/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      bio
      capital
      portfoliovalue
      rank
      lastratingdate
      type
      createdAt
      capitalHistory {
        date
        capital
        sp500capital
      }
      SP500priceattimeofcreation
      SP500capitalbenchmark
      isverified
      belongstoorg
      orgposition
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      bio
      capital
      portfoliovalue
      rank
      lastratingdate
      type
      createdAt
      capitalHistory {
        date
        capital
        sp500capital
      }
      SP500priceattimeofcreation
      SP500capitalbenchmark
      isverified
      belongstoorg
      orgposition
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      bio
      capital
      portfoliovalue
      rank
      lastratingdate
      type
      createdAt
      capitalHistory {
        date
        capital
        sp500capital
      }
      SP500priceattimeofcreation
      SP500capitalbenchmark
      isverified
      belongstoorg
      orgposition
      updatedAt
    }
  }
`;
export const createFollowing = /* GraphQL */ `
  mutation CreateFollowing(
    $input: CreateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    createFollowing(input: $input, condition: $condition) {
      id
      followerid
      followingid
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateFollowing = /* GraphQL */ `
  mutation UpdateFollowing(
    $input: UpdateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    updateFollowing(input: $input, condition: $condition) {
      id
      followerid
      followingid
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollowing = /* GraphQL */ `
  mutation DeleteFollowing(
    $input: DeleteFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    deleteFollowing(input: $input, condition: $condition) {
      id
      followerid
      followingid
      type
      createdAt
      updatedAt
    }
  }
`;
export const createUnsubscribedEmails = /* GraphQL */ `
  mutation CreateUnsubscribedEmails(
    $input: CreateUnsubscribedEmailsInput!
    $condition: ModelUnsubscribedEmailsConditionInput
  ) {
    createUnsubscribedEmails(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateUnsubscribedEmails = /* GraphQL */ `
  mutation UpdateUnsubscribedEmails(
    $input: UpdateUnsubscribedEmailsInput!
    $condition: ModelUnsubscribedEmailsConditionInput
  ) {
    updateUnsubscribedEmails(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnsubscribedEmails = /* GraphQL */ `
  mutation DeleteUnsubscribedEmails(
    $input: DeleteUnsubscribedEmailsInput!
    $condition: ModelUnsubscribedEmailsConditionInput
  ) {
    deleteUnsubscribedEmails(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      longorshort
      assetclass
      ticker
      name
      tokenid
      title
      keywords
      content
      inwhichscenarioswouldthisfail
      targetmarketcap
      targetdate
      maxpossiblereturn
      projectedirr
      actualreturnsinceposted
      stillinvested
      whydidyouchangeyourmind
      authorId
      type
      createdAt
      lastratingdate
      yesterdaysreturn
      cumulativereturn
      cumulativecapitalgained
      recentmarketcap
      marketcapattimeofpost
      marketcapattimeofexit
      numberofinvestors
      numberofinvestorslong
      numberofinvestorsshort
      numberofcomments
      averagepercentinvestedlong
      averagecapitalinvestedlong
      alluserstotalcapitalinvestedlong
      alluserstotalpercentinvestedlong
      averagepercentinvestedshort
      averagecapitalinvestedshort
      alluserstotalcapitalinvestedshort
      alluserstotalpercentinvestedshort
      usercapitalinvested
      userpercentinvested
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      longorshort
      assetclass
      ticker
      name
      tokenid
      title
      keywords
      content
      inwhichscenarioswouldthisfail
      targetmarketcap
      targetdate
      maxpossiblereturn
      projectedirr
      actualreturnsinceposted
      stillinvested
      whydidyouchangeyourmind
      authorId
      type
      createdAt
      lastratingdate
      yesterdaysreturn
      cumulativereturn
      cumulativecapitalgained
      recentmarketcap
      marketcapattimeofpost
      marketcapattimeofexit
      numberofinvestors
      numberofinvestorslong
      numberofinvestorsshort
      numberofcomments
      averagepercentinvestedlong
      averagecapitalinvestedlong
      alluserstotalcapitalinvestedlong
      alluserstotalpercentinvestedlong
      averagepercentinvestedshort
      averagecapitalinvestedshort
      alluserstotalcapitalinvestedshort
      alluserstotalpercentinvestedshort
      usercapitalinvested
      userpercentinvested
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      longorshort
      assetclass
      ticker
      name
      tokenid
      title
      keywords
      content
      inwhichscenarioswouldthisfail
      targetmarketcap
      targetdate
      maxpossiblereturn
      projectedirr
      actualreturnsinceposted
      stillinvested
      whydidyouchangeyourmind
      authorId
      type
      createdAt
      lastratingdate
      yesterdaysreturn
      cumulativereturn
      cumulativecapitalgained
      recentmarketcap
      marketcapattimeofpost
      marketcapattimeofexit
      numberofinvestors
      numberofinvestorslong
      numberofinvestorsshort
      numberofcomments
      averagepercentinvestedlong
      averagecapitalinvestedlong
      alluserstotalcapitalinvestedlong
      alluserstotalpercentinvestedlong
      averagepercentinvestedshort
      averagecapitalinvestedshort
      alluserstotalcapitalinvestedshort
      alluserstotalpercentinvestedshort
      usercapitalinvested
      userpercentinvested
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      authorid
      postid
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      authorid
      postid
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      authorid
      postid
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const createRating = /* GraphQL */ `
  mutation CreateRating(
    $input: CreateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    createRating(input: $input, condition: $condition) {
      id
      postid
      authorlongorshort
      userlongorshortpost
      usernetlongorshort
      authorid
      userid
      percentofportfolio
      capitalinvested
      name
      tokenid
      title
      keywords
      content
      inwhichscenarioswouldthisfail
      marketcapattimeofpost
      targetmarketcap
      targetdate
      ticker
      marketcapattimeofrating
      recentmarketcap
      assetclass
      maxpossiblereturn
      projectedirr
      actualreturnsinceposted
      whydidyouchangeyourmind
      lastratingdate
      stillinvested
      cumulativereturn
      cumulativecapitalgained
      type
      createdAt
      marketcapattimeofexit
      yes
      updatedAt
    }
  }
`;
export const updateRating = /* GraphQL */ `
  mutation UpdateRating(
    $input: UpdateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    updateRating(input: $input, condition: $condition) {
      id
      postid
      authorlongorshort
      userlongorshortpost
      usernetlongorshort
      authorid
      userid
      percentofportfolio
      capitalinvested
      name
      tokenid
      title
      keywords
      content
      inwhichscenarioswouldthisfail
      marketcapattimeofpost
      targetmarketcap
      targetdate
      ticker
      marketcapattimeofrating
      recentmarketcap
      assetclass
      maxpossiblereturn
      projectedirr
      actualreturnsinceposted
      whydidyouchangeyourmind
      lastratingdate
      stillinvested
      cumulativereturn
      cumulativecapitalgained
      type
      createdAt
      marketcapattimeofexit
      yes
      updatedAt
    }
  }
`;
export const deleteRating = /* GraphQL */ `
  mutation DeleteRating(
    $input: DeleteRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    deleteRating(input: $input, condition: $condition) {
      id
      postid
      authorlongorshort
      userlongorshortpost
      usernetlongorshort
      authorid
      userid
      percentofportfolio
      capitalinvested
      name
      tokenid
      title
      keywords
      content
      inwhichscenarioswouldthisfail
      marketcapattimeofpost
      targetmarketcap
      targetdate
      ticker
      marketcapattimeofrating
      recentmarketcap
      assetclass
      maxpossiblereturn
      projectedirr
      actualreturnsinceposted
      whydidyouchangeyourmind
      lastratingdate
      stillinvested
      cumulativereturn
      cumulativecapitalgained
      type
      createdAt
      marketcapattimeofexit
      yes
      updatedAt
    }
  }
`;
