import './App.css';
import Home from './Components/home.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './Components/login';
import Signup from './Components/signup';
import Tl from './Components/tl';
import Createpost from './Components/createpost';
import Profile from './Components/profile';
import awsconfig from "./aws-exports";
import { Amplify } from "aws-amplify";
import Comments from './Components/comments';
import Tickerlist from './Components/tickerlist';
import Leaderboard from './Components/leaderboard';
import InviteToOrg from './Components/invitetoorg';
import Acceptinvite from './Components/acceptinvite';
//import Following from './Components/following';
//import Followers from './Components/followers';

Amplify.configure(awsconfig);

function App() {
  return (<Router>
    <div className="App">
      <Routes className="routes-container">
        <Route exact path="/signin" element={<Home />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<Tl />} />
        <Route exact path="/selectticker" element={<Createpost />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/Comments" element={<Comments />} />
        <Route exact path="/ticker" element={<Tickerlist />} />
        <Route exact path="/leaderboard" element={<Leaderboard />} />
        <Route exact path="/invite" element={<InviteToOrg />} />
        <Route exact path="/acceptinvite" element={<Acceptinvite />} />


        
{/*         <Route exact path="/following" element={<Following />} />
        <Route exact path="/followers" element={<Followers />} />
 */}

      </Routes>
    </div>
    </Router>);
}

export default App;
