import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import awsconfig from "../aws-exports";
import { Amplify } from "aws-amplify";
import "../Components/styles/home.css"

// Material UI imports
import { Button, Container, Link, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import PGlogo from './PGlogo.png';

import whitelogo10 from './whitelogo10.png';

Amplify.configure(awsconfig);

const Home = () => {
  return (
    <Container 
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      p: '16px',
    }}
    className="Home">

      <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        p: '16px',
        padding: "200px",
      }}
      >
      <img className="logo on homepage" src={whitelogo10} alt="PG Logo" />

      <Typography className='titleOfPG' variant="h2">Pitch Goblin</Typography>


        <br />
      <Typography className='slogan' variant="h5">Build credibility and find alpha, fast.</Typography>
      <br />

      <Link component={RouterLink} to='/login' underline='none'>
        <Button
        className='loginbutton'>log in</Button></Link>
      <Link component={RouterLink} to='/signup' underline='none'>
        <Button 
        className='signupbutton'>sign up</Button></Link>
      </Box>


      
    </Container>
  )
}
export default Home;
