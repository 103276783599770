import React, { useState, useEffect, useRef } from 'react';
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import awsconfig from '../aws-exports';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { useNavigate } from 'react-router-dom';
import'./styles/signup.css';

import Button from "@mui/material/Button";
import { styled } from "@mui/system";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import whitelogo10 from './whitelogo10.png';

import './styles/login.css';

Amplify.configure(awsconfig);

const InviteToOrg = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const hasMountedRef = useRef(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const TechyButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#0a3d62",
    color: "#ffffff",
    textTransform: "uppercase",
    fontWeight: "bold",
    borderRadius: "4px",
    border: "2px solid #0a3d62",
    padding: theme.spacing(1, 2),
    "&:hover": {
      backgroundColor: "#1864ab",
      border: "2px solid #1864ab",
    },
  }));
  
  const handleLogin = async () => {
    try {


     window.confirm('Are you sure you want to invite ' + username.toLocaleLowerCase() + ' to your organization: ' + window.location.href.split("orgid=")[1] + '?');





/*

 const getThisPost = posts.find((post) => post.id === postId);

            const messageIs = "Pitch Goblin: " + userIdIs + " just rated your post a score of " + sliderValues[postId] + ". Your post: " + getThisPost.title;
            const nameIs = "You have new notifications. Click to view your post: " + "https://pitchgoblin.com/comments?postid=" + postId;
            const payload = {
              emailAddress: emailValueIsSomeoneElse, // Replace with the desired recipient's email address
              message: messageIs,
              name: nameIs,
            };
              fetch(invokeUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              })
                .then((response) => response.json())
                .catch((error) => console.error('Error:', error));
            


*/


    const invokeUrl = 'https://vcvb5sjjqc.execute-api.us-east-1.amazonaws.com/dev';

    const getEmailOfUser = await API.graphql(graphqlOperation(queries.getUser, { id: username.toLocaleLowerCase() }));
    const emailIsThis = getEmailOfUser.data.getUser.email;

    
    const payload = {
        username: username,
        orgid: window.location.href.split("orgid=")[1],
        password: password,
        email: emailIsThis,
    };

    console.log(emailIsThis)
    console.log(window.location.href.split("orgid=")[1])
    console.log(username)
    console.log(password)
    
    fetch(invokeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });



      alert('User invited successfully.')
     navigate('/');



    } catch (error) {
      console.error('Error inviting:', error);
      alert('User may not exist. Make sure you are spelling the username correctly.');
    }
  };

  const plsCheckUserFirst = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // get the orgid fromm the url params
      const orgId= window.location.href.split("orgid=")[1];
      if (user.username === orgId) {
        console.log('SUCCESS');
      } else {
        alert('FAIL');
      }

      console.log(orgId)
    } catch (error) {
      console.log('User is not logged in.');
    }
  };

  useEffect(() => {
    if (!hasMountedRef.current) {
       plsCheckUserFirst();
      hasMountedRef.current = true;
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
            
        <Box
          component="form"
          className='login-form'
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <img  src={whitelogo10} alt="PG Logo" className="pg-logo-on-login" />

          <TextField
            className='login-username'
            label="user to invite"
            type="text"
            value={username.toLocaleLowerCase()}
            onChange={handleUsernameChange}
            required
          />
          <TextField
            className='login-username'
            label="position of user in org"
            type="text"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <TechyButton type="submit" className='login-button'>invite</TechyButton>

        </Box>
        
      </Box>

  );

};

export default InviteToOrg;
