import React, { useState, useEffect, useRef } from 'react';
import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../Components/styles/tl.css"
import "../Components/styles/slider.css"
import { withAuthenticator } from '@aws-amplify/ui-react';
import { TextField, Button, Box, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: "sk-ndLtBq9EkBPLAOp1rLRUT3BlbkFJZSSXVQKHEiSpkKtcX2V",
});
const openai = new OpenAIApi(configuration);

Amplify.configure(awsconfig);

const Tl = () => {
    const [posts, setPosts] = useState([]);
    const [lockedPosts, setLockedPosts] = useState({});
    const [sliderValues, setSliderValues] = useState({});
    const [ratings, setRatings] = useState({});  
    const [marketCapOfRating, setMarketCapOfRating] = useState({});
    const [userIdIs, setUserIdIs] = useState();
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [irrHovered, setIrrHovered] = useState(false);
    const [convictionHovered, setConvictionHovered] = useState(false);
    const [riskHovered, setRiskHovered] = useState(false);
    const [timeHovered, setTimeHovered] = useState(false);
    const [psychologyHovered, setPsychologyHovered] = useState(false);
    const [exitedPositionHovered, setExitedPositionHovered] = useState(false);
    const [yourRatingOfThisPostHovered, setYourRatingOfThisPostHovered] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ratingIs, setRatingIs] = useState();
    const [aiAnswer, setAiAnswer] = useState("");
    const [userCapital, setUserCapital] = useState(0);


    const searchParams = new URLSearchParams(window.location.search);
    const tickerIsThis = searchParams.get('ticker');


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    useEffect(() => {
      if (!hasMountedRef.current) {
          fetchUserId();
          fetchPosts();
          getUserRating();
        hasMountedRef.current = true;
      }
    }, []);


    // get userrating
    const getUserRating = async () => {
      // Get the user name using auth from aws amplify
      const user = await Auth.currentAuthenticatedUser();
      
      
      const usernameIsThisHere = user.username;
      const userInfo = await API.graphql(graphqlOperation(queries.getUser, { id: usernameIsThisHere }));
      
      // Get all this users posts filtered by stillinvested = true
      const allPosts = await API.graphql(graphqlOperation(queries.listPosts, {
        filter: {
          authorId: {
            eq: usernameIsThisHere
          },
          stillinvested: {
            eq: true
          }
        }
      }));
      console.log('All Posts:', allPosts);
    
      const allPostsArray = allPosts.data.listPosts.items;
      
      // Fetch current market cap and calculate usercapitalreturn for each post concurrently
      const postsWithReturns = await Promise.all(allPostsArray.map(async (post) => {
        let mcap; 
        if (post.assetclass === 'crypto') {
          const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${post.tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRGw`);
          const data = await response.json();
          mcap = data.market_caps[0][1];
        } else if (post.assetclass === 'stock') {
          const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${post.ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39D`);
          const dataOne = await responseOne.json();
          const price = dataOne.results.p;
          const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${post.ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39D`);
          const data = await response.json();
          const weightedshares = data.results.weighted_shares_outstanding;
          mcap = price * weightedshares;
        }


        let usercapitalreturn;

        if (post.longorshort === 'long') {
          usercapitalreturn = (mcap / post.marketcapattimeofpost) * post.usercapitalinvested;
          console.log("The post.marketcapattimeofpost is:", post.marketcapattimeofpost, "and the mcap is:", mcap, "and the post.usercapitalinvested is:", post.usercapitalinvested, "and the usercapitalreturn is:", usercapitalreturn, "and the post was long")
          console.log("the post was long and the usercapitalreturn is:", usercapitalreturn)
        } else if (post.longorshort === 'short') {
          usercapitalreturn = (post.marketcapattimeofpost - mcap)/(post.marketcapattimeofpost) * post.usercapitalinvested + post.usercapitalinvested;
          console.log("The post.marketcapattimeofpost is:", post.marketcapattimeofpost, "and the mcap is:", mcap, "and the post.usercapitalinvested is:", post.usercapitalinvested, "and the usercapitalreturn is:", usercapitalreturn, "and the post was short")
        }
    

        if (post.assetclass === 'crypto') {
        console.log(`Market Cap at Time of Post for ${post.tokenid}:`, post.marketcapattimeofpost);
        console.log(`Recent Market Cap for ${post.tokenid}:`, mcap);
        } else if (post.assetclass === 'stock') {
          console.log(`Market Cap at Time of Post for ${post.ticker}:`, post.marketcapattimeofpost);
          console.log(`Recent Market Cap for ${post.ticker}:`, mcap);
        }
        
        return {
          ...post,
          recentmarketcap: mcap,
          usercapitalreturn: usercapitalreturn
        };
      })).catch(error => console.log('Error in Promise.all():', error));
      
      console.log('Post with Returns:', postsWithReturns);
    
      // Compute total user capital return
      const totalusercapitalreturnfortokens = postsWithReturns.reduce((sum, post) => sum + post.usercapitalreturn, 0);
      console.log('Total User Capital Return for Tokens:', totalusercapitalreturnfortokens);


        // Get all this users ratings filtered by stillinvested = true
        const allRatings = await API.graphql(graphqlOperation(queries.listRatings, {
          filter: {
            userid: {
              eq: usernameIsThisHere
            },
            stillinvested: {
              eq: true
            }
          }
        }));
        console.log('All Ratings:', allRatings);

        const allRatingsArray = allRatings.data.listRatings.items;
        console.log('All Ratings Array:', allRatingsArray); 

        // Fetch current market cap and calculate usercapitalreturn for each rating concurrently
        const ratingsWithReturns = await Promise.all(allRatingsArray.map(async (rating) => {
          let mcap;
          if (rating.assetclass === 'crypto') {
            const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${rating.tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRGw`);
            const data = await response.json();
            mcap = data.market_caps[0][1];
          } else if (rating.assetclass === 'stock') {
            const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${rating.ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39D`);
            const dataOne = await responseOne.json();
            const price = dataOne.results.p;
            const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${rating.ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39D`);
            const data = await response.json();
            const weightedshares = data.results.weighted_shares_outstanding;
            mcap = price * weightedshares;
          }


          let usercapitalreturn;

          if (rating.usernetlongorshort === 'long') {
            usercapitalreturn = (mcap / rating.marketcapattimeofrating) * rating.capitalinvested;
            console.log("The rating.marketcapattimeofrating is:", rating.marketcapattimeofrating, "and the mcap is:", mcap, "and the rating.capitalinvested is:", rating.capitalinvested, "and the usercapitalreturn is:", usercapitalreturn, "and the rating was long")
            
          } else if (rating.usernetlongorshort === 'short') {
            usercapitalreturn = ((rating.marketcapattimeofrating - mcap)/(rating.marketcapattimeofrating)) * rating.capitalinvested + rating.capitalinvested;
            console.log("The rating.marketcapattimeofrating is:", rating.marketcapattimeofrating, "and the mcap is:", mcap, "and the rating.capitalinvested is:", rating.capitalinvested, "and the usercapitalreturn is:", usercapitalreturn, "and the rating was short")
          }


          if (rating.assetclass === 'crypto') {
            console.log(`Market Cap at Time of Rating for ${rating.tokenid}:`, rating.marketcapattimeofrating);
            console.log(`Recent Market Cap for ${rating.tokenid}:`, mcap);
          } else if (rating.assetclass === 'stock') {
            console.log(`Market Cap at Time of Rating for ${rating.ticker}:`, rating.marketcapattimeofrating);
            console.log(`Recent Market Cap for ${rating.ticker}:`, mcap);
          }

          return {
            ...rating,
            recentmarketcap: mcap,
            usercapitalreturn: usercapitalreturn
          };
        })).catch(error => console.log('Error in Promise.all():', error));

        console.log('Ratings with Returns:', ratingsWithReturns);

        // Compute total user capital return from ratings

        const totalusercapitalreturnsfromratings = ratingsWithReturns.reduce((sum, rating) => sum + rating.usercapitalreturn, 0);
        console.log('Total User Capital Return from Ratings:', totalusercapitalreturnsfromratings);

    
      const userRating = totalusercapitalreturnfortokens + userInfo.data.getUser.capital + totalusercapitalreturnsfromratings;
      console.log('User Rating:', userRating);
      
      const thisIsIt = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(userRating);
      console.log('Final Formatted Rating:', thisIsIt);

      setRatingIs(thisIsIt);
      setUserCapital(userInfo.data.getUser.capital);
    };


    const navigate = useNavigate();
    const hasMountedRef = useRef(false);

    const handleChange = (event, postId) => {
      setSliderValues(prevState => ({
        ...prevState,
        [postId]: event.target.value
      }));
    };
    
    const handleLock = async (postId, authorId, ticker, assetClass,
      longorshortoriginal, name, title, keywords, content, inwhichscenarioswouldthisfail,
      marketcapattimeofpost, targetmarketcap, targetdate, maxpossiblereturn, projectedirr, tokenid
      ) => {
      // get the user id
      const authUser = await Auth.currentAuthenticatedUser().catch(() => null);
      // if the user is not authenticated, redirect to the home page
      if (!authUser) {
        goToCreateAccountPage();
      } else {
        const userId = authUser.username;
      // check if user has already rated this post
      const { data } = await API.graphql(graphqlOperation(queries.listRatings, { filter: { postid: { eq: postId }, userid: { eq: userId } } }));
      if (data.listRatings.items.length > 0 && data.listRatings.items[0].stillinvested === true) {
        alert("You have already rated this post");
      } else {
      setLockedPosts(prevState => ({
        ...prevState,
        [postId]: userId
      }));
      // check if it is the user's own post
      if (userId === authorId) {
        alert("You cannot rate your own post");
      } else {
      if (assetClass === 'stock') {
      // check if user has rated any post with this ticker
      const checkIfUserHasRatedAnyPostWithThisTicker = await API.graphql(graphqlOperation(queries.listRatings, { filter: { ticker: { eq: ticker }, userid: { eq: userId } } }));
      if (checkIfUserHasRatedAnyPostWithThisTicker.data.listRatings.items.length > 0 && checkIfUserHasRatedAnyPostWithThisTicker.data.listRatings.items[0].stillinvested === true) {
        // if yes, then alert the user that they have already rated a post with this ticker and that they can only rate one post with each ticker
        console.log("checkIfUserHasRatedAnyPostWithThisTicker is:", checkIfUserHasRatedAnyPostWithThisTicker.data.listRatings.items);
        alert("You have already rated a post with this ticker. You can only rate one post with each ticker.");
      } else {
           if (assetClass === 'stock')
           {
             // get User 
             const user = await Auth.currentAuthenticatedUser();
             const usernameIsThisHere = user.username;
             // get the user's userrating
             const getThisUser = await API.graphql(graphqlOperation(queries.getUser, { id: usernameIsThisHere }));
             const previousConvictionPoints = getThisUser.data.getUser.capital;
 
             // check if adding this rating will put the user over the max conviction points
             const newConvictionPoints = previousConvictionPoints - (Math.abs(sliderValues[postId])*0.1*previousConvictionPoints);
             if (newConvictionPoints < 0) {
               alert("You cannot rate this post because you don't have enough capital. You currently only have $" + previousConvictionPoints + ". Exit an existing position to free up cash.");
             } else {
              // add the conviction points to the user
              const infoToUpdate = {
                id: usernameIsThisHere,
                capital: newConvictionPoints
              } 
              await API.graphql(graphqlOperation(mutations.updateUser, { input: infoToUpdate }));
              const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39D`);
              const dataOne = await responseOne.json();
              const price = dataOne.results.p;
              const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39D`);
              const data = await response.json();
              const weightedshares = data.results.weighted_shares_outstanding;
              const mcap = price * weightedshares;
                const now = new Date();
                const options = { timeZone: 'America/New_York' };
                const todayEST = now.toLocaleString('en-US', options);
                // get the seconds since 1970
                const ratingIdIsThis = Date.now().toString();
                // and create a random number between 0 and 4 digits long to add to the end of the postId to make it unique
                const randomNumber = Math.floor(Math.random() * 10000);
                const idOfThisRatingIs = ratingIdIsThis + "-rand-" + randomNumber.toString();
              let longorshortvaluebethis;
              let diduserlongorshortpost;

              if (sliderValues[postId] > 0) {
                diduserlongorshortpost = "long";
              } else if (sliderValues[postId] < 0) {
                diduserlongorshortpost = "short";
              }

              if (sliderValues[postId] > 0 && longorshortoriginal === "long" || sliderValues[postId] < 0 && longorshortoriginal === "short") {
                longorshortvaluebethis = "long";
              } else if (sliderValues[postId] < 0 && longorshortoriginal === "long" || sliderValues[postId] > 0 && longorshortoriginal === "short") {
                longorshortvaluebethis = "short";
              }

              // createnewrating function (create new rating)
              await API.graphql(graphqlOperation(mutations.createRating, {
                input: {
                  id: idOfThisRatingIs,
                  usernetlongorshort: longorshortvaluebethis,
                  authorlongorshort: longorshortoriginal,
                  userlongorshortpost: diduserlongorshortpost,
                  ticker: ticker,
                  postid: postId,
                  authorid: authorId,
                  userid: userIdIs,
                  percentofportfolio: Math.abs(sliderValues[postId])*0.1*ratingIs,
                  capitalinvested: Math.abs(sliderValues[postId])*0.1*previousConvictionPoints,
                  name: name,
                  title: title,
                  keywords: keywords,
                  content: content, 
                  inwhichscenarioswouldthisfail: inwhichscenarioswouldthisfail,
                  targetmarketcap: targetmarketcap,
                  targetdate: targetdate,
                  marketcapattimeofpost: marketcapattimeofpost,
                  marketcapattimeofrating: mcap,
                  recentmarketcap: mcap,
                  assetclass: assetClass, 
                  maxpossiblereturn: maxpossiblereturn,
                  projectedirr: projectedirr,
                  actualreturnsinceposted: 0,
                  lastratingdate: todayEST,
                  stillinvested: true,
                  cumulativereturn: 0,
                  cumulativecapitalgained: 0,
                  type: "rating",
                  marketcapattimeofexit: 0,
                }
              }));
                const getPostWithThisId = await API.graphql(graphqlOperation(queries.getPost, { id: postId }));
                const postIs = getPostWithThisId.data.getPost;
                const numberofinvestorsare = postIs.numberofinvestors + 1;

                let numberofinvestorslongare;
                let numberofinvestorsshortare;

                if (longorshortvaluebethis === "long") {
                  numberofinvestorslongare = postIs.numberofinvestorslong + 1;
                  numberofinvestorsshortare = postIs.numberofinvestorsshort;
                } else if (longorshortvaluebethis === "short") {
                  numberofinvestorslongare = postIs.numberofinvestorslong;
                  numberofinvestorsshortare = postIs.numberofinvestorsshort + 1;
                }

                let allUsersTotalCapitalInvestedUpdatedLong;
                let averageCapitalInvestedLong;
                let totalinvestmentpercentinvestedLong;
                let averageInvestmentPercentLong;
    
                let allUsersTotalCapitalInvestedUpdatedShort;
                let averageCapitalInvestedShort;
                let totalinvestmentpercentinvestedShort;
                let averageInvestmentPercentShort;
    
            // change allUsersTotalCapitalInvestedUpdated to 0 if longorshortvaluebethis is short
            if (longorshortvaluebethis === "short"  ) {
              allUsersTotalCapitalInvestedUpdatedLong = postIs.alluserstotalcapitalinvestedlong;
              averageCapitalInvestedLong = postIs.averagecapitalinvestedlong;
              totalinvestmentpercentinvestedLong = postIs.alluserstotalcapitalinvestedlong;
              averageInvestmentPercentLong = postIs.averagepercentinvestedlong;
              allUsersTotalCapitalInvestedUpdatedShort = postIs.alluserstotalcapitalinvestedshort + Math.abs(sliderValues[postId])*0.1*previousConvictionPoints;
              averageCapitalInvestedShort = allUsersTotalCapitalInvestedUpdatedShort / numberofinvestorsshortare;
              totalinvestmentpercentinvestedShort = postIs.alluserstotalcapitalinvestedshort + Math.abs(sliderValues[postId])*0.1;
              averageInvestmentPercentShort = totalinvestmentpercentinvestedShort / numberofinvestorsshortare;
            } else if (longorshortvaluebethis === "long") {
              allUsersTotalCapitalInvestedUpdatedLong = postIs.alluserstotalcapitalinvestedlong + Math.abs(sliderValues[postId])*0.1*previousConvictionPoints;
              averageCapitalInvestedLong = allUsersTotalCapitalInvestedUpdatedLong / numberofinvestorslongare;
              totalinvestmentpercentinvestedLong = postIs.alluserstotalcapitalinvestedlong + Math.abs(sliderValues[postId])*0.1;
              averageInvestmentPercentLong = totalinvestmentpercentinvestedLong / numberofinvestorslongare;
              allUsersTotalCapitalInvestedUpdatedShort = postIs.alluserstotalcapitalinvestedshort;
              averageCapitalInvestedShort = postIs.averagecapitalinvestedshort;
              totalinvestmentpercentinvestedShort = postIs.alluserstotalcapitalinvestedshort;
              averageInvestmentPercentShort = postIs.averagepercentinvestedshort;
            }            
            await API.graphql(graphqlOperation(mutations.updatePost, { input: {
              id: postId, numberofinvestors: numberofinvestorsare, 
              averagepercentinvestedlong: averageInvestmentPercentLong,
              averagecapitalinvestedlong: averageCapitalInvestedLong,
              alluserstotalcapitalinvestedlong: allUsersTotalCapitalInvestedUpdatedLong,
              alluserstotalpercentinvestedlong: totalinvestmentpercentinvestedLong,
              averagepercentinvestedshort: averageInvestmentPercentShort,
              averagecapitalinvestedshort: averageCapitalInvestedShort,
              alluserstotalcapitalinvestedshort: allUsersTotalCapitalInvestedUpdatedShort,
              alluserstotalpercentinvestedshort: totalinvestmentpercentinvestedShort,
            } }));


            const invokeUrl = 'https://4vg6tibaog.execute-api.us-east-1.amazonaws.com/alpha';
            const emailOfAuthor = await API.graphql(graphqlOperation(queries.getUser, { id: authorId }));
            const emailValueIsSomeoneElse = emailOfAuthor.data.getUser.email;
            console.log("emailValueIsSomeoneElse is:", emailValueIsSomeoneElse);

            const getThisPost = posts.find((post) => post.id === postId);

            let messageIs;

            function formatTheNumber(num) {
              //make the num a positive num so matter what
              num = Math.abs(num);
              if (num >= 1e12) {
                return (num / 1e12).toFixed(1) + 'T';
              } else if (num >= 1e9) {
                return (num / 1e9).toFixed(1) + 'B';
              } else if (num >= 1e6) {
                return (num / 1e6).toFixed(1) + 'M';
              } else if (num >= 1e3) {
                return (num / 1e3).toFixed(0) + 'K';
              } else {
                return num;
              }
            }


            if (sliderValues[postId] > 0) {
              messageIs = "Pitch Goblin: " + userIdIs + " invested $" + formatTheNumber(Math.abs((sliderValues[postId])*0.1*previousConvictionPoints)) + " in your post: " + getThisPost.title;
            } else if (sliderValues[postId] < 0) {
              messageIs = "Pitch Goblin: " + userIdIs + " shorted your post with $" + formatTheNumber(Math.abs((sliderValues[postId])*0.1*previousConvictionPoints)) + ". Post: " + getThisPost.title;
            }

            const nameIs = "You have new notifications. Click to view your post: " + "https://pitchgoblin.com/comments?postid=" + postId;
            const payload = {
              emailAddress: emailValueIsSomeoneElse, // Replace with the desired recipient's email address
              message: messageIs,
              name: nameIs,
            };
              fetch(invokeUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              })
                .then((response) => response.json())
                .catch((error) => console.error('Error:', error));

              alert("You have successfully rated this stock! You can now see your rating on your profile page.")
              fetchPosts();
              }
            }
            }
          } else if (assetClass === 'crypto') {
             // get User 
             const user = await Auth.currentAuthenticatedUser();
             const usernameIsThisHere = user.username;
             // get the user's userrating
             const getThisUser = await API.graphql(graphqlOperation(queries.getUser, { id: usernameIsThisHere }));
             const previousConvictionPoints = getThisUser.data.getUser.capital;
 
             // check if adding this rating will put the user over the max conviction points
             const newConvictionPoints = previousConvictionPoints - (Math.abs(sliderValues[postId])*0.1*previousConvictionPoints);
             if (newConvictionPoints < 0) {
               alert("You cannot rate this post because you don't have enough conviction points. You currently have " + previousConvictionPoints + " conviction points. Exit an existing position to free up conviction points.");
             } else {
              // add the conviction points to the user 
              const infoToUpdate = {
                id: usernameIsThisHere,
                capital: newConvictionPoints
              } 
              await API.graphql(graphqlOperation(mutations.updateUser, { input: infoToUpdate }));
            const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRGw`);
            const data = await response.json();
            const mcap = data.market_caps[0][1];
              const now = new Date();
              const options = { timeZone: 'America/New_York' };
              const todayEST = now.toLocaleString('en-US', options);
              console.log(todayEST);
              // get the seconds since 1970
              const ratingIdIsThis = Date.now().toString();
              // and create a random number between 0 and 4 digits long to add to the end of the postId to make it unique
              const randomNumber = Math.floor(Math.random() * 10000);
              const idOfThisRatingIs = ratingIdIsThis + "-rand-" + randomNumber.toString();
              let longorshortvaluebethis;
              let diduserlongorshortpost;

              if (sliderValues[postId] > 0) {
                diduserlongorshortpost = "long";
              } else if (sliderValues[postId] < 0) {
                diduserlongorshortpost = "short";
              }

              if (sliderValues[postId] > 0 && longorshortoriginal === "long" || sliderValues[postId] < 0 && longorshortoriginal === "short") {
                longorshortvaluebethis = "long";
              } else if (sliderValues[postId] < 0 && longorshortoriginal === "long" || sliderValues[postId] > 0 && longorshortoriginal === "short") {
                longorshortvaluebethis = "short";
              }

              // createnewrating function (create new rating)
            await API.graphql(graphqlOperation(mutations.createRating, {
            input: {
              id: idOfThisRatingIs,
              userlongorshortpost: diduserlongorshortpost,
              authorlongorshort: longorshortoriginal,
              usernetlongorshort: longorshortvaluebethis,
              tokenid: tokenid,
              postid: postId,
              authorid: authorId,
              userid: userIdIs,
              percentofportfolio: Math.abs(sliderValues[postId])*0.1*ratingIs,
              capitalinvested: Math.abs(sliderValues[postId])*0.1*previousConvictionPoints,
              name: name,
              title: title,
              keywords: keywords,
              content: content, 
              inwhichscenarioswouldthisfail: inwhichscenarioswouldthisfail,
              targetmarketcap: targetmarketcap,
              targetdate: targetdate,
              marketcapattimeofpost: marketcapattimeofpost,
              marketcapattimeofrating: mcap,
              recentmarketcap: mcap,
              assetclass: assetClass, 
              maxpossiblereturn: maxpossiblereturn,
              projectedirr: projectedirr,
              actualreturnsinceposted: 0,
              lastratingdate: todayEST,
              stillinvested: true,
              cumulativereturn: 0,
              cumulativecapitalgained: 0,
              type: "rating",
              marketcapattimeofexit: 0,
            }
          }));
            const getPostWithThisId = await API.graphql(graphqlOperation(queries.getPost, { id: postId }));
            const postIs = getPostWithThisId.data.getPost;
            const numberofinvestorsare = postIs.numberofinvestors + 1;

            let numberofinvestorslongare;
            let numberofinvestorsshortare;

            if (longorshortvaluebethis === "long") {
              numberofinvestorslongare = postIs.numberofinvestorslong + 1;
              numberofinvestorsshortare = postIs.numberofinvestorsshort;
            } else if (longorshortvaluebethis === "short") {
              numberofinvestorslongare = postIs.numberofinvestorslong;
              numberofinvestorsshortare = postIs.numberofinvestorsshort + 1;
            }

            let allUsersTotalCapitalInvestedUpdatedLong;
            let averageCapitalInvestedLong;
            let totalinvestmentpercentinvestedLong;
            let averageInvestmentPercentLong;

            let allUsersTotalCapitalInvestedUpdatedShort;
            let averageCapitalInvestedShort;
            let totalinvestmentpercentinvestedShort;
            let averageInvestmentPercentShort;
    
            // change allUsersTotalCapitalInvestedUpdated to 0 if longorshortvaluebethis is short
            if (longorshortvaluebethis === "short"  ) {
              allUsersTotalCapitalInvestedUpdatedLong = postIs.alluserstotalcapitalinvestedlong;
              averageCapitalInvestedLong = postIs.averagecapitalinvestedlong;
              totalinvestmentpercentinvestedLong = postIs.alluserstotalcapitalinvestedlong;
              averageInvestmentPercentLong = postIs.averagepercentinvestedlong;
              allUsersTotalCapitalInvestedUpdatedShort = postIs.alluserstotalcapitalinvestedshort + Math.abs(sliderValues[postId])*0.1*previousConvictionPoints;
              averageCapitalInvestedShort = allUsersTotalCapitalInvestedUpdatedShort / numberofinvestorsshortare;
              totalinvestmentpercentinvestedShort = postIs.alluserstotalcapitalinvestedshort + Math.abs(sliderValues[postId])*0.1;
              averageInvestmentPercentShort = totalinvestmentpercentinvestedShort / numberofinvestorsshortare;
            } else if (longorshortvaluebethis === "long") {
              allUsersTotalCapitalInvestedUpdatedLong = postIs.alluserstotalcapitalinvestedlong + Math.abs(sliderValues[postId])*0.1*previousConvictionPoints;
              averageCapitalInvestedLong = allUsersTotalCapitalInvestedUpdatedLong / numberofinvestorslongare;
              totalinvestmentpercentinvestedLong = postIs.alluserstotalcapitalinvestedlong + Math.abs(sliderValues[postId])*0.1;
              averageInvestmentPercentLong = totalinvestmentpercentinvestedLong / numberofinvestorslongare;
              allUsersTotalCapitalInvestedUpdatedShort = postIs.alluserstotalcapitalinvestedshort;
              averageCapitalInvestedShort = postIs.averagecapitalinvestedshort;
              totalinvestmentpercentinvestedShort = postIs.alluserstotalcapitalinvestedshort;
              averageInvestmentPercentShort = postIs.averagepercentinvestedshort;
            }            
            await API.graphql(graphqlOperation(mutations.updatePost, { input: {
              id: postId, numberofinvestors: numberofinvestorsare, 
              averagepercentinvestedlong: averageInvestmentPercentLong,
              averagecapitalinvestedlong: averageCapitalInvestedLong,
              alluserstotalcapitalinvestedlong: allUsersTotalCapitalInvestedUpdatedLong,
              alluserstotalpercentinvestedlong: totalinvestmentpercentinvestedLong,
              averagepercentinvestedshort: averageInvestmentPercentShort,
              averagecapitalinvestedshort: averageCapitalInvestedShort,
              alluserstotalcapitalinvestedshort: allUsersTotalCapitalInvestedUpdatedShort,
              alluserstotalpercentinvestedshort: totalinvestmentpercentinvestedShort,
            } }));



          const invokeUrl = 'https://4vg6tibaog.execute-api.us-east-1.amazonaws.com/alpha';
            const emailOfAuthor = await API.graphql(graphqlOperation(queries.getUser, { id: authorId }));
            const emailValueIsSomeoneElse = emailOfAuthor.data.getUser.email;
            console.log("emailValueIsSomeoneElse is:", emailValueIsSomeoneElse);

            const getThisPost = posts.find((post) => post.id === postId);

            let messageIs;

            function formatTheNumber(num) {
              //make the num a positive num so matter what
              num = Math.abs(num);
              if (num >= 1e12) {
                return (num / 1e12).toFixed(1) + 'T';
              } else if (num >= 1e9) {
                return (num / 1e9).toFixed(1) + 'B';
              } else if (num >= 1e6) {
                return (num / 1e6).toFixed(1) + 'M';
              } else if (num >= 1e3) {
                return (num / 1e3).toFixed(0) + 'K';
              } else {
                return num;
              }
            }


            if (sliderValues[postId] > 0) {
              messageIs = "Pitch Goblin: " + userIdIs + " invested $" + formatTheNumber(Math.abs((sliderValues[postId])*0.1*previousConvictionPoints)) + " in your post: " + getThisPost.title;
            } else if (sliderValues[postId] < 0) {
              messageIs = "Pitch Goblin: " + userIdIs + " shorted your post with $" + formatTheNumber(Math.abs((sliderValues[postId])*0.1*previousConvictionPoints)) + ". Post: " + getThisPost.title;
            }

            const nameIs = "You have new notifications. Click to view your post: " + "https://pitchgoblin.com/comments?postid=" + postId;
            const payload = {
              emailAddress: emailValueIsSomeoneElse, // Replace with the desired recipient's email address
              message: messageIs,
              name: nameIs,
            };
              fetch(invokeUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              })
                .then((response) => response.json())
                .catch((error) => console.error('Error:', error));
          alert("You have successfully rated this token! You can now see your rating on your profile page.")
          fetchPosts();
          }
        } else {
          alert("We don't support this asset class yet. Please try the stock asset class for ticker lookup.");
      }}}}};


    const signout = async () => {
      try {
        await Auth.signOut();
        navigate('/');
    } catch (error) {
        console.log('Error signing out: ', error);
    }
};

const goToCreateAccountPage = async () => {
  navigate("/signin");
};


      const fetchUserId = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const userIdIsThis = user.username;
            setUserIdIs(userIdIsThis);
        } catch (error) {
            console.log("Error fetching user ID:", error);
        }
    }


    const fetchPosts = async () => {
      const { data } = await API.graphql(graphqlOperation(queries.postsByDate, {
        type: 'pitch',
        limit: 10,
        sortKeyFields: 'createdAt',
        sortDirection: 'DESC',
        filter: { ticker: {eq: tickerIsThis} }, 
    
    
      }));
      setPosts(data.postsByDate.items);
    
      // fetch user's ratings for all the posts
      const authUserIsThis = await Auth.currentAuthenticatedUser();
      const userId = authUserIsThis.username;
      const { data: ratingsData } = await API.graphql(graphqlOperation(queries.listRatings, { filter: { userid: { eq: userId },  stillinvested: {eq: true} } }));
      const userRatings = {};
      ratingsData.listRatings.items.forEach(rating => {
        userRatings[rating.postid] = rating.value;
      });
      setRatings(userRatings);
      console.log("called the fetchPosts function once");
    }

    // refresh market cap of a specific rating
    const refreshPrice = async (id, ticker, assetClass) => {
      try {
        if (assetClass === 'stock') {
          const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39D`);
          const data = await response.json();
          const mcap = data.results.market_cap;
          console.log("market cap is:", mcap);
          setMarketCapOfRating(prevMarketCaps => {
            return {...prevMarketCaps, [id]: mcap};
          });
        } else {
          alert("We don't have market cap data for this asset class yet");
        }
      } catch (error) {
        console.log("error fetching price", error);
        alert("We are experiencing high demand right now. Please try again later.");
      }
    };
    
    const aiIsHere = async (name, longOrShort, title, marketCap, targetMarketCap, targetDate, content, inwhichscenarioswouldthisfail, riskHere, conviction) => {
      setLoading(true);
      const completion = await openai.createChatCompletion({
          model: "gpt-4",
          messages: [
          {role: "system", content: "You are Ava, a super-intelligent investing AI assistant. Your role is to take the investment pitch and provide feedback on the logic of it's arguments. Suggest areas of improvement. Be creative, think deeply about what the pitch could be missing. The goal is to help determine if the pitch truly does have a special insight that is not yet being priced into the asset's market price. Return your answer using paragraphs and bullet points so that it is more easily readable: separate using \n\n for paragraphs and \n for bullet points."},
          {role: "user", content: "Investment name: " + name + 
          " \nLong or short: " + longOrShort + " \nTitle: " + title +
          " \nCurrent market cap: " + marketCap + 
          " \nTarget market cap: " + targetMarketCap + " \nTarget end date: " + targetDate  + 
          " \nWhat do you see that the market doesn't? :" + content + " \nWhat would cause this to fail? " + inwhichscenarioswouldthisfail + 
          " \nRisk: " + riskHere + " \nConviction: " + conviction}
        ],
        });

        const answer = completion.data.choices[0].message.content;
        setTimeout(() => {
          setLoading(false);
          // Replace this with the actual answer when you call the API
          setAiAnswer(answer);
        }, 2000);
      }
  
      const exitRatingWithPostId = async (id) => {
        try {
          const { data: ratingsData } = await API.graphql(graphqlOperation(queries.listRatings, {
            filter: {
              postid: { eq: id },
              userid: { eq: userIdIs },
              stillinvested: {eq: true}
            } }));
          const updateRatingInput = {
            id: ratingsData.listRatings.items[0].id,
            stillinvested: false,
          };
          await API.graphql(graphqlOperation(mutations.updateRating, { input: updateRatingInput }));
          alert("You have exited your rating");
          // refresh the page
          window.location.reload();
        } catch (error) {
          console.log("error exiting rating", error);
        }
      };
    
    const exitPosition = async (id) => {
      try {
        const updatePostInput = {
          id: id,
          stillinvested: false,
        };
        await API.graphql(graphqlOperation(mutations.updatePost, { input: updatePostInput }));
        alert("You have exited your position");
        // refresh the page
        window.location.reload();
      } catch (error) {
        console.log("error exiting position", error);
      }
    };

    // search function for search bar
    const search = async (searchTermIs) => {
      const { data } = await API.graphql(graphqlOperation(queries.listPosts, { filter: { ticker: { eq: searchTerm } } }));  
      setPosts(data.listPosts.items);
    };

  return (
    <div className="tl">

{aiAnswer === "" && (
      <div>
      <br />
      <br />
      <Box>
      <Button
        id="demo-positioned-button"
        variant='outlined'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'white' }}
      >
        {userIdIs}: {ratingIs}
      </Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { bgcolor: '#0a3d62', color: 'white' },
        }}
      >
        <MenuItem onClick={handleClose}><a className='a' href={`/profile?authorId=${encodeURIComponent(userIdIs)}`}>Profile</a></MenuItem>
        <MenuItem onClick={handleClose}><Link className='a' to="/">
        Timeline </Link></MenuItem>
        <MenuItem onClick={handleClose}><Link className='a' to="/selectticker">
        Create a post </Link></MenuItem>
        <MenuItem onClick={signout}>Logout</MenuItem>
      </Menu>

      </Box>

      <br />
      <br />
      <Box sx={{ display: 'relative', alignItems: 'center'}}>
        <TextField
          sx={{ 
          marginRight: 1,
          width: '275px',
          fontSize:'20px',
        }}
          variant="outlined"
          placeholder="5x within 2 years, low risk, etc..."
          onChange={e => setSearchTerm(e.target.value)}
        />
        <Button 
        sx={{
          fontSize: '16px', // adjust the font size as needed
          padding: '10px 20px', // adjust the padding as needed
        }}
        variant="outlined" onClick={() => search(searchTerm)}>
          Search
        </Button>
      </Box>
      <br />
      <br />

      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >

      {posts.map((post, index) => {
          const targetDate = Date.parse(post.targetdate);
          const daysToTargetDate = Math.round((targetDate - Date.now()) / 86400000);
          const irr = ((post.maxpossiblereturn+1)**(365/daysToTargetDate)-1)*100;
          const postId = post.id;
          const userId = userIdIs;
          const authorId = post.authorId;
          // check if this post has been rated by this user
          const isPostLocked = lockedPosts[postId] === userId;
          const userRating = ratings[postId] || 0;
          const shouldShowSlider = !isPostLocked && !userRating;
          return (

            <Box 
              sx={{
                display: "relative",
                alignItems: "center",
                width: "400px",
                border: "1px solid #a9b8e6",
              }}
            key={post.id}>
            <a className='a' href={`/comments?postid=${encodeURIComponent(post.id)}`}>
              {!shouldShowSlider && userRating < 0 && (
          <Tooltip title="Your rating of this post" open={yourRatingOfThisPostHovered} arrow>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 className="titleontl">
              {userRating}
              <ThumbDownOffAltIcon
                onMouseEnter={() => setYourRatingOfThisPostHovered(true)}
                onMouseLeave={() => setYourRatingOfThisPostHovered(false)}
              /> {post.title}
            </h2>
          </div>
          </Tooltip>
           )}

          {!shouldShowSlider && userRating > 0 && (
            <Tooltip title="Your rating of this post" open={yourRatingOfThisPostHovered} arrow>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2 className="titleontl">
                  {userRating}
                  <ThumbUpOffAltIcon
                    onMouseEnter={() => setYourRatingOfThisPostHovered(true)}
                    onMouseLeave={() => setYourRatingOfThisPostHovered(false)}
                  /> {post.title}
                </h2>
              </div>
            </Tooltip>
            )}

          {shouldShowSlider && userRating === 0 && (
              <h2 className="titleontl">{post.title}</h2>
            )}
            <p className="ticker">{post.longorshort} {post.name} (<a className='a' href={`/ticker?ticker=${encodeURIComponent(post.ticker)}`}>{post.ticker}</a>)</p>


<Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '30px' }}>
      <Tooltip title="Projected annualized return" open={irrHovered} arrow>
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          onMouseEnter={() => setIrrHovered(true)}
          onMouseLeave={() => setIrrHovered(false)}
        >
          <p className="irricon" style={{ margin: '1px' }}><TrendingUpIcon /></p> 
          <p className="IRR">{irr.toFixed(1)}%</p>
        </div>
      </Tooltip>

      <Tooltip title="Conviction out of 10" open={convictionHovered} arrow>
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          onMouseEnter={() => setConvictionHovered(true)}
          onMouseLeave={() => setConvictionHovered(false)}
        >
          <p className='convictionicon' style={{ margin: '1px' }}><LocalFireDepartmentIcon/></p>
          <p className="conviction">{post.conviction}</p>
        </div>
      </Tooltip>

      <Tooltip title="Riskiness out of 10" open={riskHovered} arrow>
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          onMouseEnter={() => setRiskHovered(true)}
          onMouseLeave={() => setRiskHovered(false)}
        >
          <p className='riskicon' style={{ margin: '1px' }}><TrendingDownIcon/></p>
          <p className="risk">{post.risk}</p>
        </div>
      </Tooltip>

      <Tooltip title="Timeline" open={timeHovered} arrow>
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          onMouseEnter={() => setTimeHovered(true)}
          onMouseLeave={() => setTimeHovered(false)}
        >
          <p className='timeicon' style={{ margin: '1px' }}><HourglassTopIcon/></p>
          <p className="time">{daysToTargetDate} days</p>
        </div>
      </Tooltip>
    </Box>
      <Box 
        sx={{
          margin: "15px",
        }}
      >           
              <p className="thesis">Thesis: {post.content}</p>
              <p className="failure">What would cause failure: {post.inwhichscenarioswouldthisfail}</p>
{/*               <p>Yesterday's rating gain: {(post.yesterdaysratingcontribution)}</p>
 */}              <p className="createdat">Date posted: {post.createdAt.slice(0, -14)}</p>
      </Box>
{/*               <p className="mcap">Market cap at time of post: ${post.marketcapattimeofpost.toLocaleString()}</p>
              <p className="tmcap">Target market cap: ${post.targetmarketcap.toLocaleString()}</p>
 */}           
              </a>
              <p className="author">Author: <a className='a' href={`/profile?authorId=${encodeURIComponent(post.authorId)}`}>{post.authorId}</a></p>
              <Tooltip title="Ask our AI for its opinion on this pitch." open={psychologyHovered} arrow>
                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  onMouseEnter={() => setPsychologyHovered(true)}
                  onMouseLeave={() => setPsychologyHovered(false)}
                >
                <PsychologyAltIcon className="psychologyicon" 
                  onClick={() => aiIsHere(post.name, post.longorshort, post.title, post.marketcapattimeofpost, post.targetmarketcap, post.targetdate, post.content, post.inwhichscenarioswouldthisfail, post.risk, post.conviction)}
                  /> 
                </div>
              </Tooltip>
            <br />
              {loading && <div className="loader"></div>}

              {post.stillinvested === false && 
            <Tooltip title="This user has exited this position" open={exitedPositionHovered} arrow>
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              onMouseEnter={() => setExitedPositionHovered(true)}
              onMouseLeave={() => setExitedPositionHovered(false)}
            >
            <p className="stillinvested"><NotInterestedIcon/></p>
            </div>
            </Tooltip>}              
              
            {shouldShowSlider && userIdIs !== authorId && userCapital > 0 && (
              <div>
                <input
                  type="range"
                  min="-5"
                  max="5"
                  value={sliderValues[post.id] ?? 0}
                  onChange={event => handleChange(event, post.id)}
                  disabled={lockedPosts[post.id]}
                  className="sliderya"
                />
                {sliderValues[postId] > 4 && <p className="comment">I would max long this idea.</p>}
                {sliderValues[postId] > 0 && sliderValues[postId] <= 4 && <p className="comment">I would long this idea.</p>}
                {sliderValues[postId] < 0 && sliderValues[postId] >= -4 && <p className="comment">I would short this idea.</p>}
                {sliderValues[postId] < -4 && <p className="comment">I would max short this idea.</p>}  
                  <Button 
                  variant='outlined'
                  sx={{
                    fontSize: '16px', // adjust the font size as needed
                    padding: '10px 20px', // adjust the padding as needed
                    color: 'white',
                    backgroundColor: '#rgb(6, 46, 73)',
                    ":hover": {
                      backgroundColor: "#666db2",
                      color: "white",
                    },
                  }}
                  onClick={() => handleLock(post.id, post.authorId, post.ticker, post.assetclass,
                    post.longorshort, post.name, post.title, post.keywords, post.content,
                    post.inwhichscenarioswouldthisfail, post.marketcapattimeofpost, post.targetmarketcap, post.targetdate,
                    post.maxpossiblereturn, post.projectedirr, post.tokenid
                    )}>
                    Rate
                    </Button>
              </div>)}

              {!shouldShowSlider && (
               <Button 
              variant='outlined'
              sx={{
                fontSize: '16px', // adjust the font size as needed
                padding: '10px 20px', // adjust the padding as needed
                color: 'white',
                backgroundColor: '#rgb(6, 46, 73)',
                ":hover": {
                  backgroundColor: "#666db2",
                  color: "white",
                },
              }}
              onClick={() => exitRatingWithPostId(post.id)}>Exit your rating
                </Button>
              )}

            {userIdIs === authorId && post.stillinvested === true  && (
               <Button 
              variant='outlined'
              sx={{
                fontSize: '16px', // adjust the font size as needed
                padding: '10px 20px', // adjust the padding as needed
                color: 'white',
                backgroundColor: '#rgb(6, 46, 73)',
                ":hover": {
                  backgroundColor: "#666db2",
                  color: "white",
                },
              }}
              onClick={() => exitPosition(post.id)}>Exit your post
                </Button>
              )}
                  <p>
                  <RefreshIcon className='refreshicon hoverEffect' onClick={() => refreshPrice(post.id, post.ticker, post.assetclass)} />
                  Market cap: ${marketCapOfRating[post.id] ? marketCapOfRating[post.id].toLocaleString() : "CLICK REFRESH"}
                  </p>
                  <p>
                    Target market cap: ${post.targetmarketcap.toLocaleString()}
                  </p>
                <p>Return since post = { (((marketCapOfRating[post.id] -  post.marketcapattimeofpost)/post.marketcapattimeofpost)*100).toFixed(2) }% </p>
              {post.stillinvested === false && (
                <p>{post.authorId} made this return: {((post.yesterdaysmarketcap)/(post.marketcapattimeofpost))-1}</p>

              )}

            </Box>
          );
        })}
</Box>
      <br />
      <br />
    
      </div>
)}

      {aiAnswer !== "" && (

        <div className="aiAnswer">
          <Box 
          sx={{
            display: "relative",
            alignItems: "center",
            width: "400px",
            border: "1px solid #a9b8e6",
          }}
        >

        <p>Our AI's response: </p>
          <br />
          <br />
          <br />
          <p className="aiAnswerText">{aiAnswer}</p>
          </Box>
        </div>
      
      )}
    </div>

  );
}

export default Tl;