import React, { useState, useEffect, useRef } from 'react';
import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../Components/styles/tl.css"
import "../Components/styles/slider.css"
import { withAuthenticator } from '@aws-amplify/ui-react';
import { TextField, Button, Box, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { format } from 'date-fns';
import SendIcon from '@mui/icons-material/Send';
import CommentIcon from '@mui/icons-material/Comment';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import PGlogo2 from './PGlogo2.png';
import whitelogo10 from './whitelogo10.png';
import whitelogo3 from './whitelogo3.png';
import PieChartIcon from '@mui/icons-material/PieChart';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: "sk-ndLtBq9EkBPLAOp1rLRUT3BlbkFJZSSXVQKHEiSpkKtcX2V",
});
const openai = new OpenAIApi(configuration);

Amplify.configure(awsconfig);

const Tl = () => {
    const [posts, setPosts] = useState([]);
    const [lockedPosts, setLockedPosts] = useState({});
    const [sliderValues, setSliderValues] = useState({});
    const [ratings, setRatings] = useState({});  
    const [marketCapOfRating, setMarketCapOfRating] = useState({});
    const [userIdIs, setUserIdIs] = useState("visitor");
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [irrHovered, setIrrHovered] = useState(false);
    const [convictionHovered, setConvictionHovered] = useState(false);
    const [riskHovered, setRiskHovered] = useState(false);
    const [timeHovered, setTimeHovered] = useState(false);
    const [psychologyHovered, setPsychologyHovered] = useState(false);
    const [exitedPositionHovered, setExitedPositionHovered] = useState(false);
    const [yourRatingOfThisPostHovered, setYourRatingOfThisPostHovered] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ratingIs, setRatingIs] = useState();
    const [aiAnswer, setAiAnswer] = useState("");
    const [nextToken, setNextToken] = useState(null);
    const [loadingTwo, setLoadingTwo] = useState(false);
    const [longAvg, setLongAvg] = useState(true);
    const [userCapital, setUserCapital] = useState(0);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const calculateBoxWidth = () => {
      const deviceWidth = window.innerWidth;
      if (deviceWidth <= 600) {
        return deviceWidth - 80;
      } else {
        return 560;
      }
    };

    const longOrShortAvgFn = async () => {
      setLongAvg(false);
    };

    const longOrShortAvgFnInverse = async () => {
      setLongAvg(true);
    };
  
    
    const textFieldStyles = {
      marginRight: 1,
      width: calculateBoxWidth() * 0.76 + 'px',
      fontSize: isSmallScreen ? '16px' : '20px',
    };
    
    const buttonStyles = {
      fontSize: isSmallScreen ? '14px' : '16px',
      padding: isSmallScreen ? '13px 16px' : '10px 20px',
    };

    // if isSmallScreen is true make the icons smaller
    const iconStyles = {
      fontSize: isSmallScreen ? '20px' : '24px',
    };

    
    
  const boxStyles = {
    display: 'relative',  // Flex will be better to center items
    justifyContent: 'center', // Centers items on the line (left to right)
    alignItems: 'center', // Centers items on the cross-axis (top to bottom)
    width: calculateBoxWidth(), // Keep the dynamic width
    border: '2px solid #d3d3d3', // Match border color with the slider
    borderRadius: '8px', // Smooth the corners to match the slider
    padding: '15px',
    boxSizing: 'border-box', // Make sure padding does not affect the final dimensions
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', // Add some shadow for the 3D effect
  };

    useEffect(() => {
      if (!hasMountedRef.current) {
        theRefreshPageFunctionPackage();
        hasMountedRef.current = true;
      }
    }, []);

    const theRefreshPageFunctionPackage = async () => {
      fetchUserId();
      fetchPosts();
      getUserRating();
    };

    const goToCreateAccountPage = async () => {
      navigate("/signin");
    };

    const goToCreateAccountPageWarning = async () => {
      alert("You must create an account to create a post.");
      navigate("/signin");
    };

    const copyLinkToPost = async (postId) => {
      const el = document.createElement('textarea');
      el.value = `https://pitchgoblin.com/comments?postid=${postId}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Copied link to this post to clipboard!');
    };


    const getUserRating = async () => {
      // Get the user name using auth from aws amplify
      const user = await Auth.currentAuthenticatedUser();
      
      
      const usernameIsThisHere = user.username;
      const userInfo = await API.graphql(graphqlOperation(queries.getUser, { id: usernameIsThisHere }));
      
      // Get all this users posts filtered by stillinvested = true
      const allPosts = await API.graphql(graphqlOperation(queries.listPosts, {
        filter: {
          authorId: {
            eq: usernameIsThisHere
          },
          stillinvested: {
            eq: true
          }
        }
      }));
      console.log('All Posts:', allPosts);
    
      const allPostsArray = allPosts.data.listPosts.items;
      
      // Fetch current market cap and calculate usercapitalreturn for each post concurrently
      const postsWithReturns = await Promise.all(allPostsArray.map(async (post) => {
        let mcap; 
        if (post.assetclass === 'crypto') {
          const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${post.tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRG`);
          const data = await response.json();
          mcap = data.market_caps[0][1];
        } else if (post.assetclass === 'stock') {
          const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${post.ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
          const dataOne = await responseOne.json();
          const price = dataOne.results.p;
          const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${post.ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
          const data = await response.json();
          const weightedshares = data.results.weighted_shares_outstanding;
          mcap = price * weightedshares;
        }


        let usercapitalreturn;

        if (post.longorshort === 'long') {
          usercapitalreturn = (mcap / post.marketcapattimeofpost) * post.usercapitalinvested;
          console.log("The post.marketcapattimeofpost is:", post.marketcapattimeofpost, "and the mcap is:", mcap, "and the post.usercapitalinvested is:", post.usercapitalinvested, "and the usercapitalreturn is:", usercapitalreturn, "and the post was long")
          console.log("the post was long and the usercapitalreturn is:", usercapitalreturn)
        } else if (post.longorshort === 'short') {
          usercapitalreturn = (post.marketcapattimeofpost - mcap)/(post.marketcapattimeofpost) * post.usercapitalinvested + post.usercapitalinvested;
          console.log("The post.marketcapattimeofpost is:", post.marketcapattimeofpost, "and the mcap is:", mcap, "and the post.usercapitalinvested is:", post.usercapitalinvested, "and the usercapitalreturn is:", usercapitalreturn, "and the post was short")
        }
    

        if (post.assetclass === 'crypto') {
        console.log(`Market Cap at Time of Post for ${post.tokenid}:`, post.marketcapattimeofpost);
        console.log(`Recent Market Cap for ${post.tokenid}:`, mcap);
        } else if (post.assetclass === 'stock') {
          console.log(`Market Cap at Time of Post for ${post.ticker}:`, post.marketcapattimeofpost);
          console.log(`Recent Market Cap for ${post.ticker}:`, mcap);
        }
        
        return {
          ...post,
          recentmarketcap: mcap,
          usercapitalreturn: usercapitalreturn
        };
      })).catch(error => console.log('Error in Promise.all():', error));
      
      console.log('Post with Returns:', postsWithReturns);
    
      // Compute total user capital return
      const totalusercapitalreturnfortokens = postsWithReturns.reduce((sum, post) => sum + post.usercapitalreturn, 0);
      console.log('Total User Capital Return for Tokens:', totalusercapitalreturnfortokens);


        // Get all this users ratings filtered by stillinvested = true
        const allRatings = await API.graphql(graphqlOperation(queries.listRatings, {
          filter: {
            userid: {
              eq: usernameIsThisHere
            },
            stillinvested: {
              eq: true
            }
          }
        }));
        console.log('All Ratings:', allRatings);

        const allRatingsArray = allRatings.data.listRatings.items;
        console.log('All Ratings Array:', allRatingsArray); 

        // Fetch current market cap and calculate usercapitalreturn for each rating concurrently
        const ratingsWithReturns = await Promise.all(allRatingsArray.map(async (rating) => {
          let mcap;
          if (rating.assetclass === 'crypto') {
            const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${rating.tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRG`);
            const data = await response.json();
            mcap = data.market_caps[0][1];
          } else if (rating.assetclass === 'stock') {
            const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${rating.ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
            const dataOne = await responseOne.json();
            const price = dataOne.results.p;
            const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${rating.ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
            const data = await response.json();
            const weightedshares = data.results.weighted_shares_outstanding;
            mcap = price * weightedshares;
          }


          let usercapitalreturn;

          if (rating.usernetlongorshort === 'long') {
            usercapitalreturn = (mcap / rating.marketcapattimeofrating) * rating.capitalinvested;
            console.log("The rating.marketcapattimeofrating is:", rating.marketcapattimeofrating, "and the mcap is:", mcap, "and the rating.capitalinvested is:", rating.capitalinvested, "and the usercapitalreturn is:", usercapitalreturn, "and the rating was long")
            
          } else if (rating.usernetlongorshort === 'short') {
            usercapitalreturn = ((rating.marketcapattimeofrating - mcap)/(rating.marketcapattimeofrating)) * rating.capitalinvested + rating.capitalinvested;
            console.log("The rating.marketcapattimeofrating is:", rating.marketcapattimeofrating, "and the mcap is:", mcap, "and the rating.capitalinvested is:", rating.capitalinvested, "and the usercapitalreturn is:", usercapitalreturn, "and the rating was short")
          }


          if (rating.assetclass === 'crypto') {
            console.log(`Market Cap at Time of Rating for ${rating.tokenid}:`, rating.marketcapattimeofrating);
            console.log(`Recent Market Cap for ${rating.tokenid}:`, mcap);
          } else if (rating.assetclass === 'stock') {
            console.log(`Market Cap at Time of Rating for ${rating.ticker}:`, rating.marketcapattimeofrating);
            console.log(`Recent Market Cap for ${rating.ticker}:`, mcap);
          }

          return {
            ...rating,
            recentmarketcap: mcap,
            usercapitalreturn: usercapitalreturn
          };
        })).catch(error => console.log('Error in Promise.all():', error));

        console.log('Ratings with Returns:', ratingsWithReturns);

        // Compute total user capital return from ratings

        const totalusercapitalreturnsfromratings = ratingsWithReturns.reduce((sum, rating) => sum + rating.usercapitalreturn, 0);
        console.log('Total User Capital Return from Ratings:', totalusercapitalreturnsfromratings);

    
      const userRating = totalusercapitalreturnfortokens + userInfo.data.getUser.capital + totalusercapitalreturnsfromratings;
      console.log('User Rating:', userRating);
      
      const thisIsIt = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(userRating);
      console.log('Final Formatted Rating:', thisIsIt);

      setRatingIs(thisIsIt);
      setUserCapital(userInfo.data.getUser.capital);
    };


    const navigate = useNavigate();
    const hasMountedRef = useRef(false);

    const handleChange = (event, postId) => {
      setSliderValues(prevState => ({
        ...prevState,
        [postId]: event.target.value
      }));
    };
    
    const handleLock = async (postId, authorId, ticker, assetClass,
      longorshortoriginal, name, title, keywords, content, inwhichscenarioswouldthisfail,
      marketcapattimeofpost, targetmarketcap, targetdate, maxpossiblereturn, projectedirr, tokenid
      ) => {
      // get the user id
      const authUser = await Auth.currentAuthenticatedUser().catch(() => null);
      // if the user is not authenticated, redirect to the home page
      if (!authUser) {
        alert('You must be signed in to "long" or "short" a post. Please sign in or create an account.')
        goToCreateAccountPage();
      } else {
        const userId = authUser.username;
      // check if user has already rated this post
      const { data } = await API.graphql(graphqlOperation(queries.listRatings, { filter: { postid: { eq: postId }, userid: { eq: userId } } }));
      if (data.listRatings.items.length > 0 && data.listRatings.items[0].stillinvested === true) {
        alert("You have already rated this post");
      } else {
      setLockedPosts(prevState => ({
        ...prevState,
        [postId]: userId
      }));
      // check if it is the user's own post
      if (userId === authorId) {
        alert("You cannot rate your own post");
      } else {
      if (assetClass === 'stock') {
      // check if user has rated any post with this ticker
      const checkIfUserHasRatedAnyPostWithThisTicker = await API.graphql(graphqlOperation(queries.listRatings, { filter: { ticker: { eq: ticker }, userid: { eq: userId } } }));
      if (checkIfUserHasRatedAnyPostWithThisTicker.data.listRatings.items.length > 0 && checkIfUserHasRatedAnyPostWithThisTicker.data.listRatings.items[0].stillinvested === true) {
        // if yes, then alert the user that they have already rated a post with this ticker and that they can only rate one post with each ticker
        console.log("checkIfUserHasRatedAnyPostWithThisTicker is:", checkIfUserHasRatedAnyPostWithThisTicker.data.listRatings.items);
        alert("You have already rated a post with this ticker. You can only rate one post with each ticker.");
      } else {
           if (assetClass === 'stock')
           {
             // get User 
             const user = await Auth.currentAuthenticatedUser();
             const usernameIsThisHere = user.username;
             // get the user's userrating
             const getThisUser = await API.graphql(graphqlOperation(queries.getUser, { id: usernameIsThisHere }));
             const previousConvictionPoints = getThisUser.data.getUser.capital;
 
             // check if adding this rating will put the user over the max conviction points
             const newConvictionPoints = previousConvictionPoints - (Math.abs(sliderValues[postId])*0.1*previousConvictionPoints);
             if (newConvictionPoints < 0) {
               alert("You cannot rate this post because you don't have enough capital. You currently only have $" + previousConvictionPoints + ". Exit an existing position to free up cash.");
             } else {
              // add the conviction points to the user
              const infoToUpdate = {
                id: usernameIsThisHere,
                capital: newConvictionPoints
              } 
              await API.graphql(graphqlOperation(mutations.updateUser, { input: infoToUpdate }));
              const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
              const dataOne = await responseOne.json();
              const price = dataOne.results.p;
              const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
              const data = await response.json();
              const weightedshares = data.results.weighted_shares_outstanding;
              const mcap = price * weightedshares;
                const now = new Date();
                const options = { timeZone: 'America/New_York' };
                const todayEST = now.toLocaleString('en-US', options);
                // get the seconds since 1970
                const ratingIdIsThis = Date.now().toString();
                // and create a random number between 0 and 4 digits long to add to the end of the postId to make it unique
                const randomNumber = Math.floor(Math.random() * 10000);
                const idOfThisRatingIs = ratingIdIsThis + "-rand-" + randomNumber.toString();
              let longorshortvaluebethis;
              let diduserlongorshortpost;

              if (sliderValues[postId] > 0) {
                diduserlongorshortpost = "long";
              } else if (sliderValues[postId] < 0) {
                diduserlongorshortpost = "short";
              }

              if (sliderValues[postId] > 0 && longorshortoriginal === "long" || sliderValues[postId] < 0 && longorshortoriginal === "short") {
                longorshortvaluebethis = "long";
              } else if (sliderValues[postId] < 0 && longorshortoriginal === "long" || sliderValues[postId] > 0 && longorshortoriginal === "short") {
                longorshortvaluebethis = "short";
              }

              // createnewrating function (create new rating)
              await API.graphql(graphqlOperation(mutations.createRating, {
                input: {
                  id: idOfThisRatingIs,
                  usernetlongorshort: longorshortvaluebethis,
                  authorlongorshort: longorshortoriginal,
                  userlongorshortpost: diduserlongorshortpost,
                  ticker: ticker,
                  postid: postId,
                  authorid: authorId,
                  userid: userIdIs,
                  percentofportfolio: Math.abs(sliderValues[postId])*0.1*ratingIs,
                  capitalinvested: Math.abs(sliderValues[postId])*0.1*previousConvictionPoints,
                  name: name,
                  title: title,
                  keywords: keywords,
                  content: content, 
                  inwhichscenarioswouldthisfail: inwhichscenarioswouldthisfail,
                  targetmarketcap: targetmarketcap,
                  targetdate: targetdate,
                  marketcapattimeofpost: marketcapattimeofpost,
                  marketcapattimeofrating: mcap,
                  recentmarketcap: mcap,
                  assetclass: assetClass, 
                  maxpossiblereturn: maxpossiblereturn,
                  projectedirr: projectedirr,
                  actualreturnsinceposted: 0,
                  lastratingdate: todayEST,
                  stillinvested: true,
                  cumulativereturn: 0,
                  cumulativecapitalgained: 0,
                  type: "rating",
                  marketcapattimeofexit: 0,
                }
              }));
                const getPostWithThisId = await API.graphql(graphqlOperation(queries.getPost, { id: postId }));
                const postIs = getPostWithThisId.data.getPost;
                const numberofinvestorsare = postIs.numberofinvestors + 1;

                let numberofinvestorslongare;
                let numberofinvestorsshortare;

                if (longorshortvaluebethis === "long") {
                  numberofinvestorslongare = postIs.numberofinvestorslong + 1;
                  numberofinvestorsshortare = postIs.numberofinvestorsshort;
                } else if (longorshortvaluebethis === "short") {
                  numberofinvestorslongare = postIs.numberofinvestorslong;
                  numberofinvestorsshortare = postIs.numberofinvestorsshort + 1;
                }

                let allUsersTotalCapitalInvestedUpdatedLong;
                let averageCapitalInvestedLong;
                let totalinvestmentpercentinvestedLong;
                let averageInvestmentPercentLong;
    
                let allUsersTotalCapitalInvestedUpdatedShort;
                let averageCapitalInvestedShort;
                let totalinvestmentpercentinvestedShort;
                let averageInvestmentPercentShort;
    
            // change allUsersTotalCapitalInvestedUpdated to 0 if longorshortvaluebethis is short
            if (longorshortvaluebethis === "short"  ) {
              allUsersTotalCapitalInvestedUpdatedLong = postIs.alluserstotalcapitalinvestedlong;
              averageCapitalInvestedLong = postIs.averagecapitalinvestedlong;
              totalinvestmentpercentinvestedLong = postIs.alluserstotalcapitalinvestedlong;
              averageInvestmentPercentLong = postIs.averagepercentinvestedlong;
              allUsersTotalCapitalInvestedUpdatedShort = postIs.alluserstotalcapitalinvestedshort + Math.abs(sliderValues[postId])*0.1*previousConvictionPoints;
              averageCapitalInvestedShort = allUsersTotalCapitalInvestedUpdatedShort / numberofinvestorsshortare;
              totalinvestmentpercentinvestedShort = postIs.alluserstotalcapitalinvestedshort + Math.abs(sliderValues[postId])*0.1;
              averageInvestmentPercentShort = totalinvestmentpercentinvestedShort / numberofinvestorsshortare;
            } else if (longorshortvaluebethis === "long") {
              allUsersTotalCapitalInvestedUpdatedLong = postIs.alluserstotalcapitalinvestedlong + Math.abs(sliderValues[postId])*0.1*previousConvictionPoints;
              averageCapitalInvestedLong = allUsersTotalCapitalInvestedUpdatedLong / numberofinvestorslongare;
              totalinvestmentpercentinvestedLong = postIs.alluserstotalcapitalinvestedlong + Math.abs(sliderValues[postId])*0.1;
              averageInvestmentPercentLong = totalinvestmentpercentinvestedLong / numberofinvestorslongare;
              allUsersTotalCapitalInvestedUpdatedShort = postIs.alluserstotalcapitalinvestedshort;
              averageCapitalInvestedShort = postIs.averagecapitalinvestedshort;
              totalinvestmentpercentinvestedShort = postIs.alluserstotalcapitalinvestedshort;
              averageInvestmentPercentShort = postIs.averagepercentinvestedshort;
            }            
            await API.graphql(graphqlOperation(mutations.updatePost, { input: {
              id: postId, numberofinvestors: numberofinvestorsare, 
              averagepercentinvestedlong: averageInvestmentPercentLong,
              averagecapitalinvestedlong: averageCapitalInvestedLong,
              alluserstotalcapitalinvestedlong: allUsersTotalCapitalInvestedUpdatedLong,
              alluserstotalpercentinvestedlong: totalinvestmentpercentinvestedLong,
              averagepercentinvestedshort: averageInvestmentPercentShort,
              averagecapitalinvestedshort: averageCapitalInvestedShort,
              alluserstotalcapitalinvestedshort: allUsersTotalCapitalInvestedUpdatedShort,
              alluserstotalpercentinvestedshort: totalinvestmentpercentinvestedShort,
            } }));


            const invokeUrl = 'https://4vg6tibaog.execute-api.us-east-1.amazonaws.com/alpha';
            const emailOfAuthor = await API.graphql(graphqlOperation(queries.getUser, { id: authorId }));
            const emailValueIsSomeoneElse = emailOfAuthor.data.getUser.email;
            console.log("emailValueIsSomeoneElse is:", emailValueIsSomeoneElse);

            const getThisPost = posts.find((post) => post.id === postId);

            let messageIs;

            function formatTheNumber(num) {
              //make the num a positive num so matter what
              num = Math.abs(num);
              if (num >= 1e12) {
                return (num / 1e12).toFixed(1) + 'T';
              } else if (num >= 1e9) {
                return (num / 1e9).toFixed(1) + 'B';
              } else if (num >= 1e6) {
                return (num / 1e6).toFixed(1) + 'M';
              } else if (num >= 1e3) {
                return (num / 1e3).toFixed(0) + 'K';
              } else {
                return num;
              }
            }


            if (sliderValues[postId] > 0) {
              messageIs = "Pitch Goblin: " + userIdIs + " invested $" + formatTheNumber(Math.abs((sliderValues[postId])*0.1*previousConvictionPoints)) + " in your post: " + getThisPost.title;
            } else if (sliderValues[postId] < 0) {
              messageIs = "Pitch Goblin: " + userIdIs + " shorted your post with $" + formatTheNumber(Math.abs((sliderValues[postId])*0.1*previousConvictionPoints)) + ". Post: " + getThisPost.title;
            }

            const nameIs = "You have new notifications. Click to view your post: " + "https://pitchgoblin.com/comments?postid=" + postId;
            const payload = {
              emailAddress: emailValueIsSomeoneElse, // Replace with the desired recipient's email address
              message: messageIs,
              name: nameIs,
            };
              fetch(invokeUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              })
                .then((response) => response.json())
                .catch((error) => console.error('Error:', error));

              await theRefreshPageFunctionPackage();
              alert("You have successfully rated this stock! You can now see your rating on your profile page.")
              
              }
            }
            }
          } else if (assetClass === 'crypto') {
             // get User 
             const user = await Auth.currentAuthenticatedUser();
             const usernameIsThisHere = user.username;
             // get the user's userrating
             const getThisUser = await API.graphql(graphqlOperation(queries.getUser, { id: usernameIsThisHere }));
             const previousConvictionPoints = getThisUser.data.getUser.capital;
 
             // check if adding this rating will put the user over the max conviction points
             const newConvictionPoints = previousConvictionPoints - (Math.abs(sliderValues[postId])*0.1*previousConvictionPoints);
             if (newConvictionPoints < 0) {
               alert("You cannot rate this post because you don't have enough conviction points. You currently have " + previousConvictionPoints + " conviction points. Exit an existing position to free up conviction points.");
             } else {
              // add the conviction points to the user 
              const infoToUpdate = {
                id: usernameIsThisHere,
                capital: newConvictionPoints
              } 
              await API.graphql(graphqlOperation(mutations.updateUser, { input: infoToUpdate }));
            const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRG`);
            const data = await response.json();
            const mcap = data.market_caps[0][1];
              const now = new Date();
              const options = { timeZone: 'America/New_York' };
              const todayEST = now.toLocaleString('en-US', options);
              console.log(todayEST);
              // get the seconds since 1970
              const ratingIdIsThis = Date.now().toString();
              // and create a random number between 0 and 4 digits long to add to the end of the postId to make it unique
              const randomNumber = Math.floor(Math.random() * 10000);
              const idOfThisRatingIs = ratingIdIsThis + "-rand-" + randomNumber.toString();
              let longorshortvaluebethis;
              let diduserlongorshortpost;

              if (sliderValues[postId] > 0) {
                diduserlongorshortpost = "long";
              } else if (sliderValues[postId] < 0) {
                diduserlongorshortpost = "short";
              }

              if (sliderValues[postId] > 0 && longorshortoriginal === "long" || sliderValues[postId] < 0 && longorshortoriginal === "short") {
                longorshortvaluebethis = "long";
              } else if (sliderValues[postId] < 0 && longorshortoriginal === "long" || sliderValues[postId] > 0 && longorshortoriginal === "short") {
                longorshortvaluebethis = "short";
              }

              // createnewrating function (create new rating)
            await API.graphql(graphqlOperation(mutations.createRating, {
            input: {
              id: idOfThisRatingIs,
              userlongorshortpost: diduserlongorshortpost,
              authorlongorshort: longorshortoriginal,
              usernetlongorshort: longorshortvaluebethis,
              tokenid: tokenid,
              postid: postId,
              authorid: authorId,
              userid: userIdIs,
              percentofportfolio: Math.abs(sliderValues[postId])*0.1*ratingIs,
              capitalinvested: Math.abs(sliderValues[postId])*0.1*previousConvictionPoints,
              name: name,
              title: title,
              keywords: keywords,
              content: content, 
              inwhichscenarioswouldthisfail: inwhichscenarioswouldthisfail,
              targetmarketcap: targetmarketcap,
              targetdate: targetdate,
              marketcapattimeofpost: marketcapattimeofpost,
              marketcapattimeofrating: mcap,
              recentmarketcap: mcap,
              assetclass: assetClass, 
              maxpossiblereturn: maxpossiblereturn,
              projectedirr: projectedirr,
              actualreturnsinceposted: 0,
              lastratingdate: todayEST,
              stillinvested: true,
              cumulativereturn: 0,
              cumulativecapitalgained: 0,
              type: "rating",
              marketcapattimeofexit: 0,
            }
          }));
            const getPostWithThisId = await API.graphql(graphqlOperation(queries.getPost, { id: postId }));
            const postIs = getPostWithThisId.data.getPost;
            const numberofinvestorsare = postIs.numberofinvestors + 1;

            let numberofinvestorslongare;
            let numberofinvestorsshortare;

            if (longorshortvaluebethis === "long") {
              numberofinvestorslongare = postIs.numberofinvestorslong + 1;
              numberofinvestorsshortare = postIs.numberofinvestorsshort;
            } else if (longorshortvaluebethis === "short") {
              numberofinvestorslongare = postIs.numberofinvestorslong;
              numberofinvestorsshortare = postIs.numberofinvestorsshort + 1;
            }

            let allUsersTotalCapitalInvestedUpdatedLong;
            let averageCapitalInvestedLong;
            let totalinvestmentpercentinvestedLong;
            let averageInvestmentPercentLong;

            let allUsersTotalCapitalInvestedUpdatedShort;
            let averageCapitalInvestedShort;
            let totalinvestmentpercentinvestedShort;
            let averageInvestmentPercentShort;
    
            // change allUsersTotalCapitalInvestedUpdated to 0 if longorshortvaluebethis is short
            if (longorshortvaluebethis === "short"  ) {
              allUsersTotalCapitalInvestedUpdatedLong = postIs.alluserstotalcapitalinvestedlong;
              averageCapitalInvestedLong = postIs.averagecapitalinvestedlong;
              totalinvestmentpercentinvestedLong = postIs.alluserstotalcapitalinvestedlong;
              averageInvestmentPercentLong = postIs.averagepercentinvestedlong;
              allUsersTotalCapitalInvestedUpdatedShort = postIs.alluserstotalcapitalinvestedshort + Math.abs(sliderValues[postId])*0.1*previousConvictionPoints;
              averageCapitalInvestedShort = allUsersTotalCapitalInvestedUpdatedShort / numberofinvestorsshortare;
              totalinvestmentpercentinvestedShort = postIs.alluserstotalcapitalinvestedshort + Math.abs(sliderValues[postId])*0.1;
              averageInvestmentPercentShort = totalinvestmentpercentinvestedShort / numberofinvestorsshortare;
            } else if (longorshortvaluebethis === "long") {
              allUsersTotalCapitalInvestedUpdatedLong = postIs.alluserstotalcapitalinvestedlong + Math.abs(sliderValues[postId])*0.1*previousConvictionPoints;
              averageCapitalInvestedLong = allUsersTotalCapitalInvestedUpdatedLong / numberofinvestorslongare;
              totalinvestmentpercentinvestedLong = postIs.alluserstotalcapitalinvestedlong + Math.abs(sliderValues[postId])*0.1;
              averageInvestmentPercentLong = totalinvestmentpercentinvestedLong / numberofinvestorslongare;
              allUsersTotalCapitalInvestedUpdatedShort = postIs.alluserstotalcapitalinvestedshort;
              averageCapitalInvestedShort = postIs.averagecapitalinvestedshort;
              totalinvestmentpercentinvestedShort = postIs.alluserstotalcapitalinvestedshort;
              averageInvestmentPercentShort = postIs.averagepercentinvestedshort;
            }            
            await API.graphql(graphqlOperation(mutations.updatePost, { input: {
              id: postId, numberofinvestors: numberofinvestorsare, 
              averagepercentinvestedlong: averageInvestmentPercentLong,
              averagecapitalinvestedlong: averageCapitalInvestedLong,
              alluserstotalcapitalinvestedlong: allUsersTotalCapitalInvestedUpdatedLong,
              alluserstotalpercentinvestedlong: totalinvestmentpercentinvestedLong,
              averagepercentinvestedshort: averageInvestmentPercentShort,
              averagecapitalinvestedshort: averageCapitalInvestedShort,
              alluserstotalcapitalinvestedshort: allUsersTotalCapitalInvestedUpdatedShort,
              alluserstotalpercentinvestedshort: totalinvestmentpercentinvestedShort,
            } }));



          const invokeUrl = 'https://4vg6tibaog.execute-api.us-east-1.amazonaws.com/alpha';
            const emailOfAuthor = await API.graphql(graphqlOperation(queries.getUser, { id: authorId }));
            const emailValueIsSomeoneElse = emailOfAuthor.data.getUser.email;
            console.log("emailValueIsSomeoneElse is:", emailValueIsSomeoneElse);

            const getThisPost = posts.find((post) => post.id === postId);

            let messageIs;

            function formatTheNumber(num) {
              //make the num a positive num so matter what
              num = Math.abs(num);
              if (num >= 1e12) {
                return (num / 1e12).toFixed(1) + 'T';
              } else if (num >= 1e9) {
                return (num / 1e9).toFixed(1) + 'B';
              } else if (num >= 1e6) {
                return (num / 1e6).toFixed(1) + 'M';
              } else if (num >= 1e3) {
                return (num / 1e3).toFixed(0) + 'K';
              } else {
                return num;
              }
            }


            if (sliderValues[postId] > 0) {
              messageIs = "Pitch Goblin: " + userIdIs + " invested $" + formatTheNumber(Math.abs((sliderValues[postId])*0.1*previousConvictionPoints)) + " in your post: " + getThisPost.title;
            } else if (sliderValues[postId] < 0) {
              messageIs = "Pitch Goblin: " + userIdIs + " shorted your post with $" + formatTheNumber(Math.abs((sliderValues[postId])*0.1*previousConvictionPoints)) + ". Post: " + getThisPost.title;
            }

            const nameIs = "You have new notifications. Click to view your post: " + "https://pitchgoblin.com/comments?postid=" + postId;
            const payload = {
              emailAddress: emailValueIsSomeoneElse, // Replace with the desired recipient's email address
              message: messageIs,
              name: nameIs,
            };
              fetch(invokeUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              })
                .then((response) => response.json())
                .catch((error) => console.error('Error:', error));
                await theRefreshPageFunctionPackage();
                alert("You have successfully rated this token! You can now see your rating on your profile page.")
          
        }
        } else {
          alert("We don't support this asset class yet. Please try the stock asset class for ticker lookup.");
      }}}}};


    const signout = async () => {
      try {
        await Auth.signOut();
        navigate('/signin');
    } catch (error) {
        console.log('Error signing out: ', error);
    }
};

      const fetchUserId = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const userIdIsThis = user.username;
            setUserIdIs(userIdIsThis);
        } catch (error) {
            console.log("Error fetching user ID:", error);
        }
    }

      
    const fetchPosts = async (nextToken = null) => {
        setLoadingTwo(true);
      
        const { data } = await API.graphql(graphqlOperation(queries.postsByDate, {
          type: 'pitch',
          limit: 10,
          sortKeyFields: 'createdAt',
          sortDirection: 'DESC',
          nextToken,
        }));
      
        if (nextToken) {
          setPosts(prevPosts => [...prevPosts, ...data.postsByDate.items]);
        } else {
          setPosts(data.postsByDate.items);
        }
        setNextToken(data.postsByDate.nextToken);
        setLoadingTwo(false);
    
      // fetch user's ratings for all the posts

      const user = await Auth.currentAuthenticatedUser();
      const userId = user.username;

      const { data: ratingsData } = await API.graphql(graphqlOperation(queries.listRatings, { filter: { userid: { eq: userId }, stillinvested: {eq: true} } }));
      const userRatings = {};
      ratingsData.listRatings.items.forEach(rating => {
        userRatings[rating.postid] = rating.usernetlongorshort === 'short' ? -1 * rating.capitalinvested : rating.capitalinvested;
      });
      setRatings(userRatings);
      console.log(userRatings)
      
      console.log("called the fetchPosts function once");
    }

    // refresh market cap of a specific rating
    const refreshPrice = async (id, ticker, assetClass, tokenid) => {
      try {
        if (assetClass === 'stock') {
          const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
          const dataOne = await responseOne.json();
          const price = dataOne.results.p;
          const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
          const data = await response.json();
          const weightedshares = data.results.weighted_shares_outstanding;
          const mcap = price * weightedshares;
          console.log("market cap is:", mcap);
          setMarketCapOfRating(prevMarketCaps => {
            return {...prevMarketCaps, [id]: mcap};
          });
        } else if (assetClass === 'crypto') {
          const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRG`);
          const data = await response.json();
          console.log("data is:", data);
          const mcap = data.market_caps[0][1];
          setMarketCapOfRating(prevMarketCaps => {
            return {...prevMarketCaps, [id]: mcap};
          });
        }
      } catch (error) {
        console.log("error fetching price", error);
        alert("We are experiencing high demand right now. Please try again later.");
      }
    };


    const loadMorePosts = () => {
      if (nextToken) {
        fetchPosts(nextToken);
      }
    };

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !loadingTwo) {
            loadMorePosts();
          }
        },
        { threshold: 1 }
      );
    
      const sentinel = document.getElementById("sentinel");
    
      if (sentinel) {
        observer.observe(sentinel);
      }
    
      return () => {
        if (sentinel) {
          observer.unobserve(sentinel);
        }
      };
    }, [loadingTwo]);
    
    
    
    const aiIsHere = async (name, longOrShort, title, marketCap, targetMarketCap, targetDate, content, inwhichscenarioswouldthisfail, riskHere, conviction) => {
      setLoading(true);
      const completion = await openai.createChatCompletion({
          model: "gpt-4",
          messages: [
          {role: "system", content: "You are Ava, a super-intelligent investing AI assistant. Your role is to take the investment pitch and provide feedback on the logic of it's arguments. Suggest areas of improvement. Be creative, think deeply about what the pitch could be missing. The goal is to help determine if the pitch truly does have a special insight that is not yet being priced into the asset's market price."},
          {role: "user", content: "Investment name: " + name + 
          " \nLong or short: " + longOrShort + " \nTitle: " + title +
          " \nCurrent Latest market cap: " + marketCap + 
          " \nTarget Latest market cap: " + targetMarketCap + " \nTarget end date: " + targetDate  + 
          " \nWhat do you see that the market doesn't? :" + content + " \nWhat would cause this to fail? " + inwhichscenarioswouldthisfail + 
          " \nRisk: " + riskHere + " \nConviction: " + conviction}
        ],
        });

        const answer = completion.data.choices[0].message.content;
        setTimeout(() => {
          setLoading(false);
          // Replace this with the actual answer when you call the API
          setAiAnswer(answer);
        }, 2000);
      }
      
      const exitRatingWithPostId = async (postId, authorId) => {
        try {
          const { data: ratingsData } = await API.graphql(graphqlOperation(queries.listRatings, {
            filter: {
              postid: { eq: postId },
              userid: { eq: userIdIs },
              stillinvested: {eq: true}
            } }));
          // get the value of the rating 
          const assetclass = ratingsData.listRatings.items[0].assetclass;
          const ticker = ratingsData.listRatings.items[0].ticker;
          const tokenid = ratingsData.listRatings.items[0].tokenid;
          let marketcapattimeofexitofthisrating;

          if (assetclass === 'stock') {
            const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
            const dataOne = await responseOne.json();
            const price = dataOne.results.p;
            const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
            const data = await response.json();
            const weightedshares = data.results.weighted_shares_outstanding;
            const mcap = price * weightedshares;
            marketcapattimeofexitofthisrating = mcap;
            console.log("market cap is:", mcap);


          // get the user's current convictionpoint balance
          const getUserInfo = await API.graphql(graphqlOperation(queries.getUser, { id: userIdIs }));
          const currentConvictionPoints = getUserInfo.data.getUser.capital;

          const getPostWithThisId = await API.graphql(graphqlOperation(queries.getPost, { id: postId }));
          const postIs = getPostWithThisId.data.getPost;

          const longorshortvaluebethis =  ratingsData.listRatings.items[0].usernetlongorshort;
        
          const numberofinvestors = postIs.numberofinvestors - 1;
          let allUsersTotalInvestedLongUpdated;
          let allUsersTotalInvestedShortUpdated;
          let averageCapitalInvestedLongUpdated;
          let averageCapitalInvestedShortUpdated;
          let totalInvestmentPercentInvestedLongUpdated;
          let totalInvestmentPercentInvestedShortUpdated;
          let averageInvestmentPercentLongUpdated;
          let averageInvestmentPercentShortUpdated;

          let numberofinvestorslongare;
          let numberofinvestorsshortare;

          if (longorshortvaluebethis === "long") {
            numberofinvestorslongare = postIs.numberofinvestorslong - 1;
            numberofinvestorsshortare = postIs.numberofinvestorsshort;

           allUsersTotalInvestedLongUpdated = postIs.alluserstotalcapitalinvestedlong - ratingsData.listRatings.items[0].capitalinvested;
           allUsersTotalInvestedShortUpdated = postIs.alluserstotalcapitalinvestedshort;
            averageCapitalInvestedLongUpdated = allUsersTotalInvestedLongUpdated / numberofinvestorslongare;
            averageCapitalInvestedShortUpdated = postIs.averagecapitalinvestedshort;
            totalInvestmentPercentInvestedLongUpdated = postIs.alluserstotalpercentinvestedlong - ratingsData.listRatings.items[0].percentofportfolio;
            totalInvestmentPercentInvestedShortUpdated = postIs.alluserstotalpercentinvestedshort;
            averageInvestmentPercentLongUpdated = totalInvestmentPercentInvestedLongUpdated / numberofinvestorslongare;
            averageInvestmentPercentShortUpdated = postIs.averagepercentinvestedshort;

          } else if (longorshortvaluebethis === "short") {
            numberofinvestorslongare = postIs.numberofinvestorslong;
            numberofinvestorsshortare = postIs.numberofinvestorsshort - 1;
            allUsersTotalInvestedLongUpdated = postIs.alluserstotalcapitalinvestedlong;
            allUsersTotalInvestedShortUpdated = postIs.alluserstotalcapitalinvestedshort - ratingsData.listRatings.items[0].capitalinvested;
            averageCapitalInvestedLongUpdated = postIs.averagecapitalinvestedlong;
            averageCapitalInvestedShortUpdated = allUsersTotalInvestedShortUpdated / numberofinvestorsshortare;
            totalInvestmentPercentInvestedLongUpdated = postIs.alluserstotalpercentinvestedlong;
            totalInvestmentPercentInvestedShortUpdated = postIs.alluserstotalpercentinvestedshort - ratingsData.listRatings.items[0].percentofportfolio;
            averageInvestmentPercentLongUpdated = postIs.averagepercentinvestedlong;
            averageInvestmentPercentShortUpdated = totalInvestmentPercentInvestedShortUpdated / numberofinvestorsshortare;
          }

          await API.graphql(graphqlOperation(mutations.updatePost, { input: {
            id: postId, numberofinvestors: numberofinvestors, 
            averagepercentinvestedlong: averageInvestmentPercentLongUpdated,
            averagecapitalinvestedlong: averageCapitalInvestedLongUpdated,
            alluserstotalcapitalinvestedlong: allUsersTotalInvestedLongUpdated,
            alluserstotalpercentinvestedlong: totalInvestmentPercentInvestedLongUpdated,
            averagepercentinvestedshort: averageInvestmentPercentShortUpdated,
            averagecapitalinvestedshort: averageCapitalInvestedShortUpdated,
            alluserstotalcapitalinvestedshort: allUsersTotalInvestedShortUpdated,
            alluserstotalpercentinvestedshort: totalInvestmentPercentInvestedShortUpdated,
            numberofinvestorslong: numberofinvestorslongare,
            numberofinvestorsshort: numberofinvestorsshortare,                       
          } }));


          const invokeUrl = 'https://4vg6tibaog.execute-api.us-east-1.amazonaws.com/alpha';
          const emailOfAuthor = await API.graphql(graphqlOperation(queries.getUser, { id: authorId }));
          const emailValueIsSomeoneElse = emailOfAuthor.data.getUser.email;
          console.log("emailValueIsSomeoneElse is:", emailValueIsSomeoneElse);

          const messageIs = "Pitch Goblin: " + userIdIs + " just exited from your post: " + postIs.title;
          const nameIs = "You have new notifications. Click to view your post: " + "https://pitchgoblin.com/comments?postid=" + postId;
          const payload = {
            emailAddress: emailValueIsSomeoneElse, // Replace with the desired recipient's email address
            message: messageIs,
            name: nameIs,
          };
            fetch(invokeUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            })
              .then((response) => response.json())
              .catch((error) => console.error('Error:', error));

            // calculate this user's new portfoliovalue
            let positionUpdatedValue;
            if (longorshortvaluebethis === "long") {
              positionUpdatedValue = ratingsData.listRatings.items[0].capitalinvested * (mcap - ratingsData.listRatings.items[0].marketcapattimeofrating)/ratingsData.listRatings.items[0].marketcapattimeofrating + ratingsData.listRatings.items[0].capitalinvested;
            } else if (longorshortvaluebethis === "short") {
              positionUpdatedValue = ratingsData.listRatings.items[0].capitalinvested * ((ratingsData.listRatings.items[0].marketcapattimeofrating - mcap)/ratingsData.listRatings.items[0].marketcapattimeofrating) + ratingsData.listRatings.items[0].capitalinvested;
            }

          // update the user's convictionpoints balance && update 
          const updateUserInput = {
            id: userIdIs,
            capital: currentConvictionPoints + positionUpdatedValue,
          };

          await API.graphql(graphqlOperation(mutations.updateUser, { input: updateUserInput }));
          const updateRatingInput = {
            id: ratingsData.listRatings.items[0].id,
            stillinvested: false,
            marketcapattimeofexit: mcap,
            cumulativereturn: mcap / ratingsData.listRatings.items[0].marketcapattimeofrating - 1,
            cumulativecapitalgained: mcap - ratingsData.listRatings.items[0].marketcapattimeofrating,
          };
          await API.graphql(graphqlOperation(mutations.updateRating, { input: updateRatingInput }));

          alert("You have exited your position in: ", postIs.name);
          // refresh the page
          window.location.reload();

        } else if (assetclass === 'crypto') {
          const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRG`);
          const data = await response.json();
          const mcap = data.market_caps[0][1];
          marketcapattimeofexitofthisrating = mcap;

          // get the user's current convictionpoint balance
          const getUserInfo = await API.graphql(graphqlOperation(queries.getUser, { id: userIdIs }));
          const currentConvictionPoints = getUserInfo.data.getUser.capital;

          const getPostWithThisId = await API.graphql(graphqlOperation(queries.getPost, { id: postId }));
          const postIs = getPostWithThisId.data.getPost;

          const longorshortvaluebethis =  ratingsData.listRatings.items[0].usernetlongorshort;
        
          const numberofinvestors = postIs.numberofinvestors - 1;
          let allUsersTotalInvestedLongUpdated;
          let allUsersTotalInvestedShortUpdated;
          let averageCapitalInvestedLongUpdated;
          let averageCapitalInvestedShortUpdated;
          let totalInvestmentPercentInvestedLongUpdated;
          let totalInvestmentPercentInvestedShortUpdated;
          let averageInvestmentPercentLongUpdated;
          let averageInvestmentPercentShortUpdated;

          let numberofinvestorslongare;
          let numberofinvestorsshortare;

          if (longorshortvaluebethis === "long") {
            numberofinvestorslongare = postIs.numberofinvestorslong - 1;
            numberofinvestorsshortare = postIs.numberofinvestorsshort;

           allUsersTotalInvestedLongUpdated = postIs.alluserstotalcapitalinvestedlong - ratingsData.listRatings.items[0].capitalinvested;
           allUsersTotalInvestedShortUpdated = postIs.alluserstotalcapitalinvestedshort;
            averageCapitalInvestedLongUpdated = allUsersTotalInvestedLongUpdated / numberofinvestorslongare;
            averageCapitalInvestedShortUpdated = postIs.averagecapitalinvestedshort;
            totalInvestmentPercentInvestedLongUpdated = postIs.alluserstotalpercentinvestedlong - ratingsData.listRatings.items[0].percentofportfolio;
            totalInvestmentPercentInvestedShortUpdated = postIs.alluserstotalpercentinvestedshort;
            averageInvestmentPercentLongUpdated = totalInvestmentPercentInvestedLongUpdated / numberofinvestorslongare;
            averageInvestmentPercentShortUpdated = postIs.averagepercentinvestedshort;

          } else if (longorshortvaluebethis === "short") {
            numberofinvestorslongare = postIs.numberofinvestorslong;
            numberofinvestorsshortare = postIs.numberofinvestorsshort - 1;
            allUsersTotalInvestedLongUpdated = postIs.alluserstotalcapitalinvestedlong;
            allUsersTotalInvestedShortUpdated = postIs.alluserstotalcapitalinvestedshort - ratingsData.listRatings.items[0].capitalinvested;
            averageCapitalInvestedLongUpdated = postIs.averagecapitalinvestedlong;
            averageCapitalInvestedShortUpdated = allUsersTotalInvestedShortUpdated / numberofinvestorsshortare;
            totalInvestmentPercentInvestedLongUpdated = postIs.alluserstotalpercentinvestedlong;
            totalInvestmentPercentInvestedShortUpdated = postIs.alluserstotalpercentinvestedshort - ratingsData.listRatings.items[0].percentofportfolio;
            averageInvestmentPercentLongUpdated = postIs.averagepercentinvestedlong;
            averageInvestmentPercentShortUpdated = totalInvestmentPercentInvestedShortUpdated / numberofinvestorsshortare;
          }

          await API.graphql(graphqlOperation(mutations.updatePost, { input: {
            id: postId, numberofinvestors: numberofinvestors, 
            averagepercentinvestedlong: averageInvestmentPercentLongUpdated,
            averagecapitalinvestedlong: averageCapitalInvestedLongUpdated,
            alluserstotalcapitalinvestedlong: allUsersTotalInvestedLongUpdated,
            alluserstotalpercentinvestedlong: totalInvestmentPercentInvestedLongUpdated,
            averagepercentinvestedshort: averageInvestmentPercentShortUpdated,
            averagecapitalinvestedshort: averageCapitalInvestedShortUpdated, 
            alluserstotalcapitalinvestedshort: allUsersTotalInvestedShortUpdated,
            alluserstotalpercentinvestedshort: totalInvestmentPercentInvestedShortUpdated,
            numberofinvestorslong: numberofinvestorslongare,
            numberofinvestorsshort: numberofinvestorsshortare,                       
          } }));



          const invokeUrl = 'https://4vg6tibaog.execute-api.us-east-1.amazonaws.com/alpha';
          const emailOfAuthor = await API.graphql(graphqlOperation(queries.getUser, { id: authorId }));
          const emailValueIsSomeoneElse = emailOfAuthor.data.getUser.email;
          console.log("emailValueIsSomeoneElse is:", emailValueIsSomeoneElse);
          const messageIs = "Pitch Goblin: " + userIdIs + " just exited from your post: " + postIs.title;
          const nameIs = "You have new notifications. Click to view your post: " + "https://pitchgoblin.com/comments?postid=" + postId;
          const payload = {
            emailAddress: emailValueIsSomeoneElse, // Replace with the desired recipient's email address
            message: messageIs,
            name: nameIs,
          };
            fetch(invokeUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            })
              .then((response) => response.json())
              .catch((error) => console.error('Error:', error));



              let positionUpdatedValue;

              if (longorshortvaluebethis === "long") {
                positionUpdatedValue = ratingsData.listRatings.items[0].capitalinvested * (mcap - ratingsData.listRatings.items[0].marketcapattimeofrating)/ratingsData.listRatings.items[0].marketcapattimeofrating + ratingsData.listRatings.items[0].capitalinvested;
              } else if (longorshortvaluebethis === "short") {
                positionUpdatedValue = ratingsData.listRatings.items[0].capitalinvested * ((ratingsData.listRatings.items[0].marketcapattimeofrating - mcap)/ratingsData.listRatings.items[0].marketcapattimeofrating) + ratingsData.listRatings.items[0].capitalinvested;
              }
  
  
  
  
  
  
  
            // update the user's convictionpoints balance && update 
            const updateUserInput = {
              id: userIdIs,
              capital: currentConvictionPoints + positionUpdatedValue,
              
            };
          await API.graphql(graphqlOperation(mutations.updateUser, { input: updateUserInput }));
          const updateRatingInput = {
            id: ratingsData.listRatings.items[0].id,
            stillinvested: false,
            marketcapattimeofexit: mcap,
            cumulativereturn: mcap / ratingsData.listRatings.items[0].marketcapattimeofrating - 1,
            cumulativecapitalgained: mcap - ratingsData.listRatings.items[0].marketcapattimeofrating,
          };
          await API.graphql(graphqlOperation(mutations.updateRating, { input: updateRatingInput }));


          alert("You have exited your position in: ", postIs.name);
          // refresh the page
          window.location.reload();
        }
        } catch (error) {
          console.log("error exiting rating", error);
        }
      };
    

      const exitPosition = async (id) => {
        try {
          const getPostInfo = await API.graphql(graphqlOperation(queries.getPost, { id: id }));
          const assetClass = getPostInfo.data.getPost.assetclass;
          const ticker = getPostInfo.data.getPost.ticker;
          const tokenid = getPostInfo.data.getPost.tokenid;
          let marketcapattimeofexitofthispost;
          if (assetClass === 'stock') {
            const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
            const dataOne = await responseOne.json();
            const price = dataOne.results.p;
            const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
            const data = await response.json();
            const weightedshares = data.results.weighted_shares_outstanding;
            const mcap = price * weightedshares;
            marketcapattimeofexitofthispost = mcap;
          const updatePostInput = {
            id: id,
            stillinvested: false,
            marketcapattimeofexit: mcap,
          };
          const getUserInfo = await API.graphql(graphqlOperation(queries.getUser, { id: userIdIs }));
          const currentConvictionPoints = getUserInfo.data.getUser.capital;
          let positionUpdatedValue;
          if (getPostInfo.data.getPost.longorshort === "long") {
            positionUpdatedValue = getPostInfo.data.getPost.usercapitalinvested * ((mcap - getPostInfo.data.getPost.marketcapattimeofpost)/getPostInfo.data.getPost.marketcapattimeofpost) + getPostInfo.data.getPost.usercapitalinvested;
          } else if (getPostInfo.data.getPost.longorshort === "short") {
            positionUpdatedValue = getPostInfo.data.getPost.usercapitalinvested * ((getPostInfo.data.getPost.marketcapattimeofpost - mcap)/getPostInfo.data.getPost.marketcapattimeofpost) + getPostInfo.data.getPost.usercapitalinvested;
          }
        const updateUserInput = {
          id: userIdIs,
          capital: currentConvictionPoints + positionUpdatedValue,
          
        };
          await API.graphql(graphqlOperation(mutations.updateUser, { input: updateUserInput }));
          await API.graphql(graphqlOperation(mutations.updatePost, { input: updatePostInput }));
          alert("You have exited your position");
          window.location.reload();
          } else if (assetClass === 'crypto') {
            const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${tokenid}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRG`);
            const data = await response.json();
            const mcap = data.market_caps[0][1];
            marketcapattimeofexitofthispost = mcap;
            const updatePostInput = {
              id: id,
              stillinvested: false,
              marketcapattimeofexit: mcap,
            };
            const getUserInfo = await API.graphql(graphqlOperation(queries.getUser, { id: userIdIs }));
            const currentConvictionPoints = getUserInfo.data.getUser.capital;
            let positionUpdatedValue;
            if (getPostInfo.data.getPost.longorshort === "long") {
              positionUpdatedValue = getPostInfo.data.getPost.usercapitalinvested * ((mcap - getPostInfo.data.getPost.marketcapattimeofpost)/getPostInfo.data.getPost.marketcapattimeofpost) + getPostInfo.data.getPost.usercapitalinvested;
            } else if (getPostInfo.data.getPost.longorshort === "short") {
              positionUpdatedValue = getPostInfo.data.getPost.usercapitalinvested * ((getPostInfo.data.getPost.marketcapattimeofpost - mcap)/getPostInfo.data.getPost.marketcapattimeofpost) + getPostInfo.data.getPost.usercapitalinvested;
            }  
          const updateUserInput = {
            id: userIdIs,
            capital: currentConvictionPoints + positionUpdatedValue,
            
          };
            await API.graphql(graphqlOperation(mutations.updateUser, { input: updateUserInput }));
            await API.graphql(graphqlOperation(mutations.updatePost, { input: updatePostInput }));
            alert("You have exited your position");
            window.location.reload();
          }
        } catch (error) {
          console.log("error exiting position", error);
        }
      };

// search function for search bar
const search = async (searchTermIs) => {
  const { data } = await API.graphql(graphqlOperation(queries.listPosts, { 
    filter: {
      or: [
        { ticker: { contains: searchTermIs } },
        { name: { contains: searchTermIs }  },
        { title: { contains: searchTermIs } },
        { content: { contains: searchTermIs } },
        { authorId: { contains: searchTermIs } },
      ]
    },
  }));
  alert("All posts with " + searchTermIs + " in them are below.")
  setPosts(data.listPosts.items);
};

  return (
    <div className="tl">

{aiAnswer === "" && userIdIs !== "visitor" && (
      <div>
      <br />
      <br />
      <Box>

      <img  src={whitelogo3} alt="PG Logo" className="pg-logo-on-tl-page" />
      <br />
      
      <Button
        id="demo-positioned-button"
        variant='outlined'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'white' }}
      >
        {userIdIs}: {ratingIs}
      </Button>

      <Menu
        id="demo-positioned-menu" 
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { bgcolor: '#0a3d62', color: 'white' },
        }}
      >
        <MenuItem onClick={handleClose}><a className='a' href={`/profile?authorId=${encodeURIComponent(userIdIs)}`}>Profile</a></MenuItem>
        <MenuItem onClick={handleClose}><a className='a' href={`/Leaderboard`}>Leaderboard</a></MenuItem>
        <MenuItem onClick={handleClose}><Link className='a' to="/selectticker">
        Create post </Link></MenuItem>
        <MenuItem onClick={signout}>Logout</MenuItem>
      </Menu>

      </Box>

      <br />
      <br />

      <Box sx={{ display: 'relative', alignItems: 'center'}}>
        <TextField
          sx={textFieldStyles}
          variant="outlined"
          placeholder="Search posts..."
          onChange={e => setSearchTerm(e.target.value)}
        />
        <Button 
        sx={buttonStyles}
        variant="outlined" onClick={() => search(searchTerm)}>
          Search
        </Button>
      </Box>
      <br />
      <br />

      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >

      {posts.map((post, index) => {
          const targetDate = Date.parse(post.targetdate);
          const daysToTargetDate = Math.round((targetDate - Date.now()) / 86400000);
          const irr = ((post.maxpossiblereturn+1)**(365/daysToTargetDate)-1)*100;
          const postId = post.id;
          const userId = userIdIs;
          const authorId = post.authorId;
          // check if this post has been rated by this user
          const isPostLocked = lockedPosts[postId] === userId;
          const userRating = ratings[postId] || 0;

          function formatNumber(num) {
            //make the num a positive num so matter what
            num = Math.abs(num);
            if (num >= 1e12) {
              return (num / 1e12).toFixed(1) + 'T';
            } else if (num >= 1e9) {
              return (num / 1e9).toFixed(1) + 'B';
            } else if (num >= 1e6) {
              return (num / 1e6).toFixed(1) + 'M';
            } else if (num >= 1e3) {
              return (num / 1e3).toFixed(0) + 'K';
            } else {
              return num;
            }
          }

        const userRatingFormattedIs = formatNumber(userRating);

          const shouldShowSlider = !isPostLocked && !userRating;
          let averageRating = post.alluserstotalcapitalinvestedlong;
          let averageRatingShort = post.alluserstotalcapitalinvestedshort;
          let formattedLimitedAverageRating = averageRating !== null ? averageRating.toFixed(2) : 'N/A';
          let formattedLimitedAverageRatingShort = averageRatingShort !== null ? averageRatingShort.toFixed(2) : 'N/A';
          let limitedAverageRating = formattedLimitedAverageRating !== 'N/A' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(formattedLimitedAverageRating) : 'N/A';

          return (
            <Box 
              sx={boxStyles}
            key={post.id}>
              {!shouldShowSlider && userRating < 0 && (
          <Tooltip title="Your allocation to the underlying stock/token. Down = short. Up = long" open={yourRatingOfThisPostHovered} arrow>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 className="titleontl">
              ${userRatingFormattedIs}
              <ThumbDownOffAltIcon
                onMouseEnter={() => setYourRatingOfThisPostHovered(true)}
                onMouseLeave={() => setYourRatingOfThisPostHovered(false)}
              /> {post.title}
            </h2>
          </div>
          </Tooltip>
           )}

          {!shouldShowSlider && userRating > 0 && (
            <Tooltip title="Your allocation to the underlying stock/token. Down = short. Up = long" open={yourRatingOfThisPostHovered} arrow>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2 className="titleontl">
                  ${userRatingFormattedIs}
                  <ThumbUpOffAltIcon
                    onMouseEnter={() => setYourRatingOfThisPostHovered(true)}
                    onMouseLeave={() => setYourRatingOfThisPostHovered(false)}
                  /> {post.title}
                </h2>
              </div>
            </Tooltip>
            )}

          {shouldShowSlider && userRating === 0 && (
              <h2 className="titleontl">{post.title}</h2>
            )}
            <p className="ticker">{post.longorshort} {post.name} {post.assetclass === "stock" && ((



            <a className='a' href={`/ticker?ticker=${encodeURIComponent(post.ticker)}`}
            onClick={(e) => {
              e.preventDefault();
              search(post.ticker);
            }}

            
            >({post.ticker})</a>

            
            
            ))}
            </p>

            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
  
  <Tooltip title="Projected annualized return" open={irrHovered} arrow>
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      onMouseEnter={() => setIrrHovered(true)}
      onMouseLeave={() => setIrrHovered(false)}
    >
      <p className="irricon" style={{ margin: '0' }}><TrendingUpIcon /></p> 
      <p className="IRR" style={{ margin: '0' }}>{irr.toFixed(1)}%</p>
    </div>
  </Tooltip>
  <Tooltip title="User allocation" open={riskHovered} arrow>
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      onMouseEnter={() => setRiskHovered(true)}
      onMouseLeave={() => setRiskHovered(false)}
    >
      <p className='riskicon' style={{ margin: '0' }}><PieChartIcon/></p>
      <p className="risk" style={{ margin: '0' }}>${formatNumber(post.usercapitalinvested)}</p>
    </div>
  </Tooltip>
  <Tooltip title="Timeline" open={timeHovered} arrow>
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      onMouseEnter={() => setTimeHovered(true)}
      onMouseLeave={() => setTimeHovered(false)}
    >
      <p className='timeicon' style={{ margin: '0' }}><HourglassTopIcon/></p>
      <p className="time" style={{ margin: '0' }}>{daysToTargetDate} days</p>
    </div>
  </Tooltip>
</Box>


      <Box 
        sx={{
          margin: "15px",
        }}
      >           
              <p className="thesis">Thesis: {post.content}</p>
              <p className="failure">What would cause failure: {post.inwhichscenarioswouldthisfail}</p>
{/*               <p>Yesterday's rating gain: {(post.yesterdaysratingcontribution)}</p>
 */}              <p className="createdat">Date posted: {format(new Date(post.createdAt), "MMMM d, yyyy, h:mm a")}</p>
      </Box>


    
{/*              

    const timestamp = "2023-04-03T03:10:17.183Z";
    const date = new Date(post.createdAt);
    const formattedDate = format(new Date(post.createdAt), "MMMM d, yyyy, h:mm a");




<p className="mcap">Market cap at time of post: ${post.marketcapattimeofpost.toLocaleString()}</p>
              <p className="tmcap">Target Latest market cap: ${post.targetmarketcap.toLocaleString()}</p>
 */}           
              <p className="author">Author: <a className='a' href={`/profile?authorId=${encodeURIComponent(post.authorId)}`}>{post.authorId}</a></p>
{/*               <Tooltip title="Ask our AI for its opinion on this pitch." open={psychologyHovered} arrow>
                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  onMouseEnter={() => setPsychologyHovered(true)}
                  onMouseLeave={() => setPsychologyHovered(false)}
                >
                <PsychologyAltIcon className="psychologyicon" 
                  onClick={() => aiIsHere(post.name, post.longorshort, post.title, post.marketcapattimeofpost, post.targetmarketcap, post.targetdate, post.content, post.inwhichscenarioswouldthisfail, post.risk, post.conviction)}
                  /> 
                </div>
              </Tooltip>
            <br /> */}
              {loading && <div className="loader"></div>}

              {post.stillinvested === false && 
            <Tooltip title="This user has exited this position" open={exitedPositionHovered} arrow>
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              onMouseEnter={() => setExitedPositionHovered(true)}
              onMouseLeave={() => setExitedPositionHovered(false)}
            >
            <p className="stillinvested"><NotInterestedIcon/></p>
            </div>
            </Tooltip>}              
            
                  <p>
                  <RefreshIcon className='refreshicon hoverEffect' onClick={() => refreshPrice(post.id, post.ticker, post.assetclass, post.tokenid)} />
                  Latest market cap: ${marketCapOfRating[post.id] ? formatNumber(marketCapOfRating[post.id]) : "CLICK REFRESH ICON"}
                  </p>
                  <p>
                  Target market cap: ${formatNumber(post.targetmarketcap)}
                  </p>

                  {post.stillinvested === true && post.longorshort === "long" && (
                <p>
                  Pitch performance = { (((marketCapOfRating[post.id] -  post.marketcapattimeofpost)/post.marketcapattimeofpost)*100).toFixed(2) }%
                </p> )}

                {post.stillinvested === true && post.longorshort === "short" && (
                <p>
                  Pitch performance = { (((post.marketcapattimeofpost - marketCapOfRating[post.id])/post.marketcapattimeofpost)*100).toFixed(2) }%
                </p> )}
                
                
                {post.stillinvested === true && (
                  <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                  <p>
                  <a className='a' href={`/comments?postid=${encodeURIComponent(post.id)}`}> <CommentIcon
                  sx={iconStyles}
                  /> {post.numberofcomments}</a>
                  </p>

                 <p>
                 {post.numberofinvestors} Investors
                 </p>


                  {longAvg === true && (
                  <p>
                  ${formatNumber(averageRating)} total long <button onClick={longOrShortAvgFn}>
                    <ToggleOffIcon />
                  </button>
                  </p>


                  )}
                  {longAvg === false && (
                    <p>
                    ${formatNumber(averageRatingShort)} total short <button onClick={longOrShortAvgFnInverse}>
                      <ToggleOnIcon />
                    </button>
                    </p>
                  )}
                </Box>
                )}
                
                
                <Box>
                <Tooltip title="Copy link to this post" open={exitedPositionHovered} arrow>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    onMouseEnter={() => setExitedPositionHovered(true)}
                    onMouseLeave={() => setExitedPositionHovered(false)}
                  >
                  <SendIcon 
                  sx={iconStyles}
                  
                  onClick={() => copyLinkToPost(post.id)} 
                
                  />
                  </div>
                  </Tooltip>
                </Box>
                <br />


          {shouldShowSlider && userIdIs !== authorId && userCapital > 0 && (
              <div>
                  <input
                      type="range"
                      min="-10"
                      max="10"
                      value={sliderValues[post.id] ?? 0}
                      onChange={event => handleChange(event, post.id)}
                      disabled={lockedPosts[post.id]}
                      className="sliderya"
                  />
                  {
                      sliderValues[postId] < 0 && 
                      <p className="comment">Short with {Math.abs(sliderValues[postId])*10}% of capital (${formatNumber(userCapital*Math.abs(sliderValues[postId])*10/100)})</p>
                  }
                  {
                      sliderValues[postId] === 0 && 
                      <p className="comment">Neutral stance.</p>
                  }
                  {
                      sliderValues[postId] > 0 && 
                      <p className="comment">Invest {Math.abs(sliderValues[postId])*10}% of capital (${formatNumber(userCapital*Math.abs(sliderValues[postId])*10/100)})</p>
                  }

                
                  <Button 
                  variant='outlined'
                  sx={{
                    fontSize: '16px', // adjust the font size as needed
                    padding: '10px 20px', // adjust the padding as needed
                    color: 'white',
                    backgroundColor: '#rgb(6, 46, 73)',
                    ":hover": {
                      backgroundColor: "#666db2",
                      color: "white",
                    },
                  }}
                  onClick={() => handleLock(post.id, post.authorId, post.ticker, post.assetclass,
                  post.longorshort, post.name, post.title, post.keywords, post.content,
                  post.inwhichscenarioswouldthisfail, post.marketcapattimeofpost, post.targetmarketcap, post.targetdate,
                  post.maxpossiblereturn, post.projectedirr, post.tokenid
                  )}>
                    Rate
                </Button>



              </div>)}

              {!shouldShowSlider && (
               <Button 
              variant='outlined'
              sx={{
                fontSize: '16px', // adjust the font size as needed
                padding: '10px 20px', // adjust the padding as needed
                color: 'white',
                backgroundColor: '#rgb(6, 46, 73)',
                ":hover": {
                  backgroundColor: "#666db2",
                  color: "white",
                },
              }}
              onClick={() => exitRatingWithPostId(post.id, post.authorId)}>close position
                </Button>
              )}

            {userIdIs === authorId && post.stillinvested === true  && (
               <Button 
              variant='outlined'
              sx={{
                fontSize: '16px', // adjust the font size as needed
                padding: '10px 20px', // adjust the padding as needed
                color: 'white',
                backgroundColor: '#rgb(6, 46, 73)',
                ":hover": {
                  backgroundColor: "#666db2",
                  color: "white",
                },
              }}
              onClick={() => exitPosition(post.id)}>Exit your post
                </Button>
              )}


              {post.stillinvested === false && post.longorshort === "long" && (
                <p>{post.authorId} made this return: {((((post.marketcapattimeofexit)/(post.marketcapattimeofpost))-1)*100).toFixed(2)}%</p>
              )}      
              {post.stillinvested === false && post.longorshort === "short" && (
                <p>{post.authorId} made this return: {((((post.marketcapattimeofpost)/(post.marketcapattimeofexit))-1)*100).toFixed(2)}%</p>
              )} 

            </Box>
          );
        })}
</Box>
      <br />
      <br />
    
      </div>
)}

{aiAnswer === "" && userIdIs === "visitor" && (
      <div>
      <br />
      <br />
      <Box>

        
      <Button
        id="demo-positioned-button"
        variant='outlined'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'white' }}
      >
        Create your account
      </Button>

      <Menu
        id="demo-positioned-menu" 
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { bgcolor: '#0a3d62', color: 'white' },
        }}
      >
        <MenuItem onClick={goToCreateAccountPage}>Create account</MenuItem>
        <MenuItem onClick={goToCreateAccountPageWarning}>Create a post</MenuItem>
        <MenuItem onClick={handleClose}><a className='a' href={`/Leaderboard`}>Leaderboard</a></MenuItem>
      </Menu>

      </Box>

      <br />
      <br />

      <Box sx={{ display: 'relative', alignItems: 'center'}}>
        <TextField
          sx={textFieldStyles}
          variant="outlined"
          placeholder="Search posts..."
          onChange={e => setSearchTerm(e.target.value)}
        />
        <Button 
        sx={buttonStyles}
        variant="outlined" onClick={() => search(searchTerm)}>
          Search
        </Button>
      </Box>
      <br />
      <br />

      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >

      {posts.map((post, index) => {
          const targetDate = Date.parse(post.targetdate);
          const daysToTargetDate = Math.round((targetDate - Date.now()) / 86400000);
          const irr = ((post.maxpossiblereturn+1)**(365/daysToTargetDate)-1)*100;
          const postId = post.id;
          const userId = userIdIs;
          const authorId = post.authorId;
          // check if this post has been rated by this user
          const isPostLocked = lockedPosts[postId] === userId;
          const userRating = ratings[postId] || 0;

          function formatNumber(num) {
            if (num >= 1e12) {
              return (num / 1e12).toFixed(1) + 'T';
            } else if (num >= 1e9) {
              return (num / 1e9).toFixed(1) + 'B';
            } else if (num >= 1e6) {
              return (num / 1e6).toFixed(1) + 'M';
            } else if (num >= 1e3) {
              return (num / 1e3).toFixed(0) + 'K';
            } else {
              return num;
            }
          }

        const userRatingFormattedIs = formatNumber(userRating);

          const shouldShowSlider = !isPostLocked && !userRating;
          let averageRating = post.alluserstotalcapitalinvestedlong;
          let averageRatingShort = post.alluserstotalcapitalinvestedshort;
          let formattedLimitedAverageRating = averageRating !== null ? averageRating.toFixed(2) : 'N/A';
          let formattedLimitedAverageRatingShort = averageRatingShort !== null ? averageRatingShort.toFixed(2) : 'N/A';
          let limitedAverageRating = formattedLimitedAverageRating !== 'N/A' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(formattedLimitedAverageRating) : 'N/A';

          return (
            <Box 
              sx={boxStyles}
            key={post.id}>
              {!shouldShowSlider && userRating < 0 && (
          <Tooltip title="Your allocation to the underlying stock/token. Down = short. Up = long" open={yourRatingOfThisPostHovered} arrow>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 className="titleontl">
              ${userRatingFormattedIs}
              <ThumbDownOffAltIcon
                onMouseEnter={() => setYourRatingOfThisPostHovered(true)}
                onMouseLeave={() => setYourRatingOfThisPostHovered(false)}
              /> {post.title}
            </h2>
          </div>
          </Tooltip>
           )}

          {!shouldShowSlider && userRating > 0 && (
            <Tooltip title="Your allocation to the underlying stock/token. Down = short. Up = long" open={yourRatingOfThisPostHovered} arrow>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2 className="titleontl">
                  ${userRatingFormattedIs}
                  <ThumbUpOffAltIcon
                    onMouseEnter={() => setYourRatingOfThisPostHovered(true)}
                    onMouseLeave={() => setYourRatingOfThisPostHovered(false)}
                  /> {post.title}
                </h2>
              </div>
            </Tooltip>
            )}

          {shouldShowSlider && userRating === 0 && (
              <h2 className="titleontl">{post.title}</h2>
            )}
            <p className="ticker">{post.longorshort} {post.name} {post.assetclass === "stock" && ((<a className='a' href={`/ticker?ticker=${encodeURIComponent(post.ticker)}`}>({post.ticker})</a>))}
            </p>


            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '30px' }}>
  <Tooltip title="Projected annualized return" open={irrHovered} arrow>
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      onMouseEnter={() => setIrrHovered(true)}
      onMouseLeave={() => setIrrHovered(false)}
    >
      <p className="irricon" style={{ margin: '0' }}><TrendingUpIcon /></p> 
      <p className="IRR" style={{ margin: '0' }}>{irr.toFixed(1)}%</p>
    </div>
  </Tooltip>


  <Tooltip title="User allocation" open={riskHovered} arrow>
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      onMouseEnter={() => setRiskHovered(true)}
      onMouseLeave={() => setRiskHovered(false)}
    >
      <p className='riskicon' style={{ margin: '0' }}><PieChartIcon/></p>
      <p className="risk" style={{ margin: '0' }}>${formatNumber(post.usercapitalinvested)}</p>
    </div>
  </Tooltip>

  <Tooltip title="Timeline" open={timeHovered} arrow>
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      onMouseEnter={() => setTimeHovered(true)}
      onMouseLeave={() => setTimeHovered(false)}
    >
      <p className='timeicon' style={{ margin: '0' }}><HourglassTopIcon/></p>
      <p className="time" style={{ margin: '0' }}>{daysToTargetDate} days</p>
    </div>
  </Tooltip>
</Box>


      <Box 
        sx={{
          margin: "15px",
        }}
      >           
              <p className="thesis">Thesis: {post.content}</p>
              <p className="failure">What would cause failure: {post.inwhichscenarioswouldthisfail}</p>
{/*               <p>Yesterday's rating gain: {(post.yesterdaysratingcontribution)}</p>
 */}              <p className="createdat">Date posted: {format(new Date(post.createdAt), "MMMM d, yyyy, h:mm a")}</p>
      </Box>


    
{/*              

    const timestamp = "2023-04-03T03:10:17.183Z";
    const date = new Date(post.createdAt);
    const formattedDate = format(new Date(post.createdAt), "MMMM d, yyyy, h:mm a");




<p className="mcap">Market cap at time of post: ${post.marketcapattimeofpost.toLocaleString()}</p>
              <p className="tmcap">Target Latest market cap: ${post.targetmarketcap.toLocaleString()}</p>
 */}           
              <p className="author">Author: <a className='a' href={`/profile?authorId=${encodeURIComponent(post.authorId)}`}>{post.authorId}</a></p>
{/*               <Tooltip title="Ask our AI for its opinion on this pitch." open={psychologyHovered} arrow>
                <div
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  onMouseEnter={() => setPsychologyHovered(true)}
                  onMouseLeave={() => setPsychologyHovered(false)}
                >
                <PsychologyAltIcon className="psychologyicon" 
                  onClick={() => aiIsHere(post.name, post.longorshort, post.title, post.marketcapattimeofpost, post.targetmarketcap, post.targetdate, post.content, post.inwhichscenarioswouldthisfail, post.risk, post.conviction)}
                  /> 
                </div>
              </Tooltip>
            <br /> */}
              {loading && <div className="loader"></div>}

              {post.stillinvested === false && 
            <Tooltip title="This user has exited this position" open={exitedPositionHovered} arrow>
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              onMouseEnter={() => setExitedPositionHovered(true)}
              onMouseLeave={() => setExitedPositionHovered(false)}
            >
            <p className="stillinvested"><NotInterestedIcon/></p>
            </div>
            </Tooltip>}       


            <p>
                  <RefreshIcon className='refreshicon hoverEffect' onClick={() => refreshPrice(post.id, post.ticker, post.assetclass, post.tokenid)} />
                  Latest market cap: ${marketCapOfRating[post.id] ? formatNumber(marketCapOfRating[post.id]) : "CLICK REFRESH ICON"}
                  </p>
                  <p>
                  Target market cap: ${formatNumber(post.targetmarketcap)}
                  </p>

                  {post.stillinvested === true && post.longorshort === "long" && (
                <p>
                  Pitch performance = { (((marketCapOfRating[post.id] -  post.marketcapattimeofpost)/post.marketcapattimeofpost)*100).toFixed(2) }%
                </p> )}

                {post.stillinvested === true && post.longorshort === "short" && (
                <p>
                  Pitch performance = { (((post.marketcapattimeofpost - marketCapOfRating[post.id])/post.marketcapattimeofpost)*100).toFixed(2) }%
                </p> )}
                

            
              {post.stillinvested === true && (
                  <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                  <p>
                  <a className='a' href={`/comments?postid=${encodeURIComponent(post.id)}`}> <CommentIcon
                  sx={iconStyles}
                  /> {post.numberofcomments}</a>
                  </p>

                 <p>
                 {post.numberofinvestors} Investors
                 </p>


                  {longAvg === true && (
                  <p>
                  ${formatNumber(averageRating)} total long <button onClick={longOrShortAvgFn}>
                    <ToggleOffIcon />
                  </button>
                  </p>


                  )}
                  {longAvg === false && (
                    <p>
                    ${formatNumber(averageRatingShort)} total short <button onClick={longOrShortAvgFnInverse}>
                      <ToggleOnIcon />
                    </button>
                    </p>
                  )}
                </Box>
                )}       
              
{
  (
    <div>
      <input
        type="range"
        min="-10"
        max="10"
        value={sliderValues[post.id] ?? -3}
        onChange={event => handleChange(event, post.id)}
        disabled={lockedPosts[post.id]}
        className="sliderya"
      />
      <br />
      <br />

      {
            !sliderValues[postId] && 
  <p className="comment">Short this pitch with 30% of your capital ($300k)</p>
                  }


                {
                      sliderValues[postId] < 0 && 
                      <p className="comment">Short with {Math.abs(sliderValues[postId])*10}% of capital (${formatNumber(1000000*Math.abs(sliderValues[postId])*10/100)})</p>
                  }
                  {
                      sliderValues[postId] === 0 && 
                      <p className="comment">Neutral stance.</p>
                  }
                  {
                      sliderValues[postId] > 0 && 
                      <p className="comment">Invest {Math.abs(sliderValues[postId])*10}% of capital (${formatNumber(1000000*Math.abs(sliderValues[postId])*10/100)})</p>
                  } 

                
                  <Button 
                  variant='outlined'
                  sx={{
                    fontSize: '16px', // adjust the font size as needed
                    padding: '10px 20px', // adjust the padding as needed
                    color: 'white',
                    backgroundColor: '#rgb(6, 46, 73)',
                    ":hover": {
                      backgroundColor: "#666db2",
                      color: "white",
                    },
                  }}
                  onClick={() => handleLock(post.id, post.authorId, post.ticker, post.assetclass,
                    post.longorshort, post.name, post.title, post.keywords, post.content,
                    post.inwhichscenarioswouldthisfail, post.marketcapattimeofpost, post.targetmarketcap, post.targetdate,
                    post.maxpossiblereturn, post.projectedirr, post.tokenid
                    )}>
                    Rate
                </Button>



              </div>)}



              {!shouldShowSlider && (
               <Button 
              variant='outlined'
              sx={{
                fontSize: '16px', // adjust the font size as needed
                padding: '10px 20px', // adjust the padding as needed
                color: 'white',
                backgroundColor: '#rgb(6, 46, 73)',
                ":hover": {
                  backgroundColor: "#666db2",
                  color: "white",
                },
              }}
              onClick={() => exitRatingWithPostId(post.id, post.authorId)}>close position
                </Button>
              )}




{userIdIs === authorId && post.stillinvested === true  && (
               <Button 
              variant='outlined'
              sx={{
                fontSize: '16px', // adjust the font size as needed
                padding: '10px 20px', // adjust the padding as needed
                color: 'white',
                backgroundColor: '#rgb(6, 46, 73)',
                ":hover": {
                  backgroundColor: "#666db2",
                  color: "white",
                },
              }}
              onClick={() => exitPosition(post.id)}>Exit your post
                </Button>
              )}


              {post.stillinvested === false && post.longorshort === "long" && (
                <p>{post.authorId} made this return: {((((post.marketcapattimeofexit)/(post.marketcapattimeofpost))-1)*100).toFixed(2)}%</p>
              )}      
              {post.stillinvested === false && post.longorshort === "short" && (
                <p>{post.authorId} made this return: {((((post.marketcapattimeofpost)/(post.marketcapattimeofexit))-1)*100).toFixed(2)}%</p>
              )} 

            </Box>




          );
        })}
</Box>
      <br />
      <br />
    
      </div>
)}


<br />
<br />
<div id="sentinel" />


<br />
<br />
<br />
<br />

<div>
  <br />
  <br />
</div>

      {aiAnswer !== "" && (

        <div className="aiAnswer">
          <Box 
          sx={{
            display: "relative",
            alignItems: "center",
            width: "400px",
            border: "1px solid #a9b8e6",
          }}
        >

        <p>Our AI's response: </p>
          <br />
          <br />
          <br />
          <p className="aiAnswerText">{aiAnswer}</p>
          </Box>
        </div>
      
      )}
    </div>

  );
}

export default Tl;
