import React, { useState, useEffect, useRef } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { useNavigate } from 'react-router-dom';
import'./styles/signup.css';



import Button from "@mui/material/Button";
import { styled } from "@mui/system";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import whitelogo10 from './whitelogo10.png';

import './styles/login.css';

Amplify.configure(awsconfig);

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const hasMountedRef = useRef(false);


  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };



  const TechyButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#0a3d62",
    color: "#ffffff",
    textTransform: "uppercase",
    fontWeight: "bold",
    borderRadius: "4px",
    border: "2px solid #0a3d62",
    padding: theme.spacing(1, 2),
    "&:hover": {
      backgroundColor: "#1864ab",
      border: "2px solid #1864ab",
    },
  }));
  

  const handleLogin = async () => {
    try {
      await Auth.signIn(username, password);
      setUsername('');
      setPassword('');
      localStorage.setItem('userId', username);
      navigate('/');
    } catch (error) {
      console.error('Error signing in:', error);
      alert('Wrong username or password.');
    }
  };

  const plsCheckUserFirst = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        console.log('User is already logged in.');
        setUsername('');
        setPassword('');
        navigate('/');
      }
    } catch (error) {
      console.log('User is not logged in.');
    }
  };

  useEffect(() => {
    if (!hasMountedRef.current) {
       plsCheckUserFirst();
      hasMountedRef.current = true;
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
               

        <Box
          component="form"
          className='login-form'
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <img  src={whitelogo10} alt="PG Logo" className="pg-logo-on-login" />

          <TextField
            className='login-username'
            label="Username"
            type="text"
            value={username.toLocaleLowerCase().replace(/\s+/g, '')}
            onChange={handleUsernameChange}
            required
          />
          <TextField
            className='login-password'
            label="Password"
            type="password"
            value={password.replace(/\s+/g, '')}
            onChange={handlePasswordChange}
            required
          />
          <TechyButton type="submit" className='login-button'>log in</TechyButton>

        </Box>
        
      </Box>

  );

};

export default Login;
