import React, { useState, useEffect, useRef } from 'react';
import { Amplify, API, graphqlOperation, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../Components/styles/tl.css"
import "../Components/styles/slider.css"
import { withAuthenticator } from '@aws-amplify/ui-react';
import { TextField, Button, Box, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RefreshIcon from '@mui/icons-material/Refresh';

Amplify.configure(awsconfig);

const Leaderboard = () => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [yourRank, setYourRank] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userIdIs, setUserIdIs] = useState('');
    const [userRatingIs, setUserRatingIs] = useState(0);

    const navigate = useNavigate();
    const hasMountedRef = useRef(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const fetchUserId = async () => {
        try {
          // check if auth == authorId, if yes, then set isAuth to true
          const authUser = await Auth.currentAuthenticatedUser();
          const userIdIsThis = authUser.username;
            setUserIdIs(userIdIsThis);
            const getUser = await API.graphql(graphqlOperation(queries.getUser, { id: userIdIsThis }));
            const userRatingIsThis = getUser.data.getUser.portfoliovalue;


            const portfoliovalueFormatted  = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(userRatingIsThis);

            setUserRatingIs(portfoliovalueFormatted);
        } catch (error) {
            console.log("Error fetching user ID:", error);
        }
    }

    useEffect(() => {
        if (!hasMountedRef.current) {
            getLeaderboard();
            fetchUserId();
          hasMountedRef.current = true;
        }
      }, []);


      const signout = async () => {
        try {
          await Auth.signOut();
          navigate('/signin');
      } catch (error) {
          console.log('Error signing out: ', error);
      }
  };

    const getLeaderboard = async () => {
        try {
            const getAllUsers = await API.graphql(graphqlOperation(queries.listUsers));
            const allUsers = getAllUsers.data.listUsers.items;


            const allUsersLengthIs = allUsers.length;
            // rank each user by their userrating with the highest being rank 1
            const allUsersWithRanks = [];
            for (let i = 0; i < allUsersLengthIs; i++) {
              const userRating = allUsers[i].portfoliovalue;
              let rank = 1;
              for (let j = 0; j < allUsersLengthIs; j++) {
                if (allUsers[j].portfoliovalue > userRating) {
                  rank++;
                }
              }
              allUsersWithRanks.push({rank: rank, id: allUsers[i].id});
            }
            // sort the users by rank
            allUsersWithRanks.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
            // get the top 10 users
            const top10Users = allUsersWithRanks.slice(0, 10);
            // get the user data for the top 10 users (rank, id, userrating)
            const top10UsersData = [];

            
            for (let i = 0; i < top10Users.length; i++) {
                const user = allUsers.find(user => user.id === top10Users[i].id);
                const portfoliovalueFormatted  = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(user.portfoliovalue);
                top10UsersData.push({rank: top10Users[i].rank, id: user.id, portfoliovalue: portfoliovalueFormatted});
                }
            setLeaderboard(top10UsersData);  

            // get the user's rank
            const user = await Auth.currentAuthenticatedUser();
            const usernameis = user.username;
            const userRank = allUsersWithRanks.find(userRank => userRank.id === usernameis);
            setYourRank(userRank.rank);

        } catch (error) {
            console.log('error fetching leaderboard', error);
        }
    }

    const goToCreateAccountPage = async () => {
      navigate("/signin");
    };

    return (
// show the leaderboard in a table

        <Box>


        { userIdIs !== '' && (
        <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>

                <Box>

                    <br />
      <Button
        id="demo-positioned-button"
        variant='outlined'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'white' }}
      >
        {userIdIs}: {userRatingIs}
      </Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { bgcolor: '#0a3d62', color: 'white' },
        }}
      >
        <MenuItem onClick={handleClose}><a className='a' href={`/profile?authorId=${encodeURIComponent(userIdIs)}`}>Profile</a></MenuItem>
        <MenuItem onClick={handleClose}><Link className='a' to="/">
        Timeline </Link></MenuItem>        <MenuItem onClick={handleClose}><Link className='a' to="/selectticker">
        Create a post </Link></MenuItem>
        <MenuItem onClick={signout}>Logout</MenuItem>
      </Menu>
      </Box>
        </Box>
        )}

        { !userRatingIs && (
          <Box>

            <br />
            <br />
          <Button
            id="demo-positioned-button"
            variant='outlined'
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={goToCreateAccountPage}
            sx={{ color: 'white' }}
          >
            Create your account
          </Button>
    
    
          </Box>

        )}








    <br />
    <br />







       
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >

        
      <TableContainer
        sx={{
            minWidth: 300,
            maxWidth: 400,
            backgroundColor: 'transparent',
            border: '1px solid #fff',
            borderRadius: '10px',
        }}


      component={Paper}>
        <Table
            sx={{ minWidth: 300, maxWidth: 400, backgroundColor: 'transparent' }}
            size="small" aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Rank<Tooltip title="Refresh">
          <RefreshIcon className="leaderboard__refresh" onClick={getLeaderboard} />
        </Tooltip></TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Portfolio Value
                
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboard.map((user, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {user.rank}
                </TableCell>
                <TableCell>
                <a className='a' href={`/profile?authorId=${encodeURIComponent(user.id)}`}>{user.id}</a>
                  </TableCell>
                <TableCell>{user.portfoliovalue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
    </Box>
    <Box>
        <br/>
      <p>your rank: {yourRank}</p>
    </Box>
    </Box>

  );
}
export default Leaderboard;

