import React, { useState } from 'react';
import { Auth, API, Amplify, graphqlOperation  } from 'aws-amplify';
import * as mutations from "../graphql/mutations";
import awsconfig from '../aws-exports';
import { useNavigate } from 'react-router-dom';
import'./styles/signup.css';
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import whitelogo10 from './whitelogo10.png';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


Amplify.configure(awsconfig);

const Signup = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordconfirm, setPasswordconfirm] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [type, setType] = useState('user');
  const usr = 'user';
  const org = 'organization';
  const [firstPartDone, setFirstPartDone] = useState(false);
  const navigate = useNavigate();

  const TechyButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#0a3d62",
    color: "#ffffff",
    textTransform: "uppercase",
    fontWeight: "bold",
    borderRadius: "4px",
    border: "2px solid #0a3d62",
    padding: theme.spacing(1, 2),
    "&:hover": {
      backgroundColor: "#1864ab",
      border: "2px solid #1864ab",
    },
  }));

  const handleChangeOfType = (event) => {
    setType(event.target.value);
  };
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmChange = (event) => {
    setPasswordconfirm(event.target.value);
  };
  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleSubmitForVerifyEmail = async (event) => {
    event.preventDefault();
    try {
      const now = new Date();
      const options = { timeZone: 'America/New_York' };
      const todayEST = now.toLocaleString('en-US', options);
      console.log(todayEST);
      const response = await fetch(`https://api.polygon.io/v2/aggs/ticker/VOO/prev?adjusted=true&apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
      const data = await response.json();
      const shareprice = data.results[0].c;
      console.log(shareprice);
        await Auth.confirmSignUp(username.toLocaleLowerCase(), verificationCode);

        // if user is an org then


        if (type === usr) {
        await API.graphql(graphqlOperation(mutations.createUser, { input: {
          id: username.toLocaleLowerCase(),
          email: email,
          capital: 1000000,
          rank: 0,
          portfoliovalue: 1000000,
          SP500priceattimeofcreation: shareprice,
          capitalHistory: {
            date: todayEST,
            capital: 1000000,
            sp500capital: 1000000,
          },
          type: "user",
        } }));
  
        alert('Email verified successfully!');
        setVerificationCode('');
        // log the user in 
        await Auth.signIn(username.toLocaleLowerCase(), password);
        navigate('/')
      } else if (type === org) {
        await API.graphql(graphqlOperation(mutations.createUser, { input: {
          id: username.toLocaleLowerCase(),
          email: email,
          capital: 1000000,
          rank: 0,
          portfoliovalue: 1000000,
          belongstoorg: username.toLocaleLowerCase(),
          orgposition: "admin",
          SP500priceattimeofcreation: shareprice,
          capitalHistory: {
            date: todayEST,
            capital: 1000000,
            sp500capital: 1000000,
          },
          type: "user",
        } }));
        alert('Email verified successfully!');
        setVerificationCode('');
        // log the user in 
        await Auth.signIn(username.toLocaleLowerCase(), password);
        navigate('/')
      }
    } catch (error) {
      console.error('Error confirming sign-up:', error);
      alert('Error confirming sign-up. Please try again.');
      setVerificationCode('');
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (password !== passwordconfirm) {
        alert('Passwords do not match.');
      } else {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      });
      alert('Check your email for your verification code!');
      setFirstPartDone(true);
      }
    } catch (error) {
      console.error('Error signing up:', error);
      alert('Username already exists. Please pick another username.');

    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <br/>
       <img  src={whitelogo10} alt="PG Logo" className="pg-logo-on-login" />
      
      {!firstPartDone && (
      <Box
        component="form"
        className='signup-form'
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
<Select
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  value={type}
  label="Type"
  onChange={handleChangeOfType}
  style={{ color: 'white', backgroundColor: 'transparent' }}
>
  <MenuItem value={usr} style={{ color: '#003366', backgroundColor: 'transparent' }}>User</MenuItem>
  <MenuItem value={org} style={{ color: '#003366', backgroundColor: 'transparent' }}>Organization</MenuItem>
</Select>



        <TextField
          className='signup-email'
          label="Email"
          type="email"
          value={email.replace(/\s+/g, '')}
          onChange={handleEmailChange}
          required
        />
        <TextField
          className='signup-username'
          label="Username"
          type="text"
          value={username.toLocaleLowerCase().replace(/\s+/g, '')}
          onChange={handleUsernameChange}
          required
        />
        <TextField
          className='signup-password'
          label="Password"
          type="password"
          value={password.replace(/\s+/g, '')}
          onChange={handlePasswordChange}
          required
        />
        <TextField
          className='signup-passwordconfirm'
          label="Confirm password"
          type="password"
          value={passwordconfirm.replace(/\s+/g, '')}
          onChange={handlePasswordConfirmChange}
          required
        />
        <TechyButton type="submit">Sign Up</TechyButton>
      </Box>
      )}
      {firstPartDone && (
        
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Typography variant="h4" className='verifyemailtitle'></Typography>
        <Box
          component="form"
          className='verifyemail-form'
          onSubmit={handleSubmitForVerifyEmail}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <TextField
            className='verifyemail-password'
            label="Verification code"
            type="text"
            value={verificationCode.replace(/\s+/g, '')}
            onChange={handleVerificationCodeChange}
            required
          />
          <TechyButton type="submit">verify email</TechyButton>
        </Box>
      </Box>
        
        )}
    </Box>


  );
}

export default Signup;