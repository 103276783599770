import React, { useState, useEffect, useRef } from 'react';
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import awsconfig from '../aws-exports';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import'./styles/signup.css';

import Button from "@mui/material/Button";
import { styled } from "@mui/system";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import whitelogo10 from './whitelogo10.png';
import { useNavigate } from 'react-router-dom';

import './styles/login.css';

Amplify.configure(awsconfig);

const Acceptinvite = () => {
  const navigate = useNavigate();
  const hasMountedRef = useRef(false);

  const plsCheckUserFirst = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const urlParams = new URLSearchParams(window.location.search);
      const orgId = urlParams.get('orgid');
      const userId = urlParams.get('user');
      let userPassword = urlParams.get('password');
      userPassword = decodeURIComponent(userPassword);
      // check if there are spaces shown as %20 in the url and replace them with spaces 
      // so that the user can see the spaces in the url
      const userPasswordNoSpaces = userPassword.replace(/%20/g, ' ');

    if (user.username === userId) {
            const infoToUpdate = {
                id: userId,
                belongstoorg: orgId,
                orgposition: userPasswordNoSpaces,
            }
            await API.graphql(graphqlOperation(mutations.updateUser, { input: infoToUpdate }));
            alert('User invited successfully.')
            navigate('/home');
    } else { 
        alert('You are not logged in as the user who was invited. Please log in as the user who was invited to accept the invite.')
        navigate('/home');
      }
    } catch (error) {
      console.log('User is not logged in.');
    }
  };

  useEffect(() => {
    if (!hasMountedRef.current) {
       plsCheckUserFirst();
      hasMountedRef.current = true;
    }
  }, []);



  return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
    
      </Box>

  );

};

export default Acceptinvite;
