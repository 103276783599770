/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      bio
      capital
      portfoliovalue
      rank
      lastratingdate
      type
      createdAt
      capitalHistory {
        date
        capital
        sp500capital
      }
      SP500priceattimeofcreation
      SP500capitalbenchmark
      isverified
      belongstoorg
      orgposition
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        bio
        capital
        portfoliovalue
        rank
        lastratingdate
        type
        createdAt
        capitalHistory {
          date
          capital
          sp500capital
        }
        SP500priceattimeofcreation
        SP500capitalbenchmark
        isverified
        belongstoorg
        orgposition
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollowing = /* GraphQL */ `
  query GetFollowing($id: ID!) {
    getFollowing(id: $id) {
      id
      followerid
      followingid
      type
      createdAt
      updatedAt
    }
  }
`;
export const listFollowings = /* GraphQL */ `
  query ListFollowings(
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        followerid
        followingid
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnsubscribedEmails = /* GraphQL */ `
  query GetUnsubscribedEmails($id: ID!) {
    getUnsubscribedEmails(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const listUnsubscribedEmails = /* GraphQL */ `
  query ListUnsubscribedEmails(
    $filter: ModelUnsubscribedEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnsubscribedEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      longorshort
      assetclass
      ticker
      name
      tokenid
      title
      keywords
      content
      inwhichscenarioswouldthisfail
      targetmarketcap
      targetdate
      maxpossiblereturn
      projectedirr
      actualreturnsinceposted
      stillinvested
      whydidyouchangeyourmind
      authorId
      type
      createdAt
      lastratingdate
      yesterdaysreturn
      cumulativereturn
      cumulativecapitalgained
      recentmarketcap
      marketcapattimeofpost
      marketcapattimeofexit
      numberofinvestors
      numberofinvestorslong
      numberofinvestorsshort
      numberofcomments
      averagepercentinvestedlong
      averagecapitalinvestedlong
      alluserstotalcapitalinvestedlong
      alluserstotalpercentinvestedlong
      averagepercentinvestedshort
      averagecapitalinvestedshort
      alluserstotalcapitalinvestedshort
      alluserstotalpercentinvestedshort
      usercapitalinvested
      userpercentinvested
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        longorshort
        assetclass
        ticker
        name
        tokenid
        title
        keywords
        content
        inwhichscenarioswouldthisfail
        targetmarketcap
        targetdate
        maxpossiblereturn
        projectedirr
        actualreturnsinceposted
        stillinvested
        whydidyouchangeyourmind
        authorId
        type
        createdAt
        lastratingdate
        yesterdaysreturn
        cumulativereturn
        cumulativecapitalgained
        recentmarketcap
        marketcapattimeofpost
        marketcapattimeofexit
        numberofinvestors
        numberofinvestorslong
        numberofinvestorsshort
        numberofcomments
        averagepercentinvestedlong
        averagecapitalinvestedlong
        alluserstotalcapitalinvestedlong
        alluserstotalpercentinvestedlong
        averagepercentinvestedshort
        averagecapitalinvestedshort
        alluserstotalcapitalinvestedshort
        alluserstotalpercentinvestedshort
        usercapitalinvested
        userpercentinvested
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      authorid
      postid
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorid
        postid
        content
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      postid
      authorlongorshort
      userlongorshortpost
      usernetlongorshort
      authorid
      userid
      percentofportfolio
      capitalinvested
      name
      tokenid
      title
      keywords
      content
      inwhichscenarioswouldthisfail
      marketcapattimeofpost
      targetmarketcap
      targetdate
      ticker
      marketcapattimeofrating
      recentmarketcap
      assetclass
      maxpossiblereturn
      projectedirr
      actualreturnsinceposted
      whydidyouchangeyourmind
      lastratingdate
      stillinvested
      cumulativereturn
      cumulativecapitalgained
      type
      createdAt
      marketcapattimeofexit
      yes
      updatedAt
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postid
        authorlongorshort
        userlongorshortpost
        usernetlongorshort
        authorid
        userid
        percentofportfolio
        capitalinvested
        name
        tokenid
        title
        keywords
        content
        inwhichscenarioswouldthisfail
        marketcapattimeofpost
        targetmarketcap
        targetdate
        ticker
        marketcapattimeofrating
        recentmarketcap
        assetclass
        maxpossiblereturn
        projectedirr
        actualreturnsinceposted
        whydidyouchangeyourmind
        lastratingdate
        stillinvested
        cumulativereturn
        cumulativecapitalgained
        type
        createdAt
        marketcapattimeofexit
        yes
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByDate = /* GraphQL */ `
  query UsersByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        bio
        capital
        portfoliovalue
        rank
        lastratingdate
        type
        createdAt
        capitalHistory {
          date
          capital
          sp500capital
        }
        SP500priceattimeofcreation
        SP500capitalbenchmark
        isverified
        belongstoorg
        orgposition
        updatedAt
      }
      nextToken
    }
  }
`;
export const followersByDate = /* GraphQL */ `
  query FollowersByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followersByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        followerid
        followingid
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const unsubscribedEmailsByEmail = /* GraphQL */ `
  query UnsubscribedEmailsByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUnsubscribedEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unsubscribedEmailsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByDate = /* GraphQL */ `
  query PostsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        longorshort
        assetclass
        ticker
        name
        tokenid
        title
        keywords
        content
        inwhichscenarioswouldthisfail
        targetmarketcap
        targetdate
        maxpossiblereturn
        projectedirr
        actualreturnsinceposted
        stillinvested
        whydidyouchangeyourmind
        authorId
        type
        createdAt
        lastratingdate
        yesterdaysreturn
        cumulativereturn
        cumulativecapitalgained
        recentmarketcap
        marketcapattimeofpost
        marketcapattimeofexit
        numberofinvestors
        numberofinvestorslong
        numberofinvestorsshort
        numberofcomments
        averagepercentinvestedlong
        averagecapitalinvestedlong
        alluserstotalcapitalinvestedlong
        alluserstotalpercentinvestedlong
        averagepercentinvestedshort
        averagecapitalinvestedshort
        alluserstotalcapitalinvestedshort
        alluserstotalpercentinvestedshort
        usercapitalinvested
        userpercentinvested
        updatedAt
      }
      nextToken
    }
  }
`;
export const commentsByDate = /* GraphQL */ `
  query CommentsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorid
        postid
        content
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ratingsByDate = /* GraphQL */ `
  query RatingsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ratingsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postid
        authorlongorshort
        userlongorshortpost
        usernetlongorshort
        authorid
        userid
        percentofportfolio
        capitalinvested
        name
        tokenid
        title
        keywords
        content
        inwhichscenarioswouldthisfail
        marketcapattimeofpost
        targetmarketcap
        targetdate
        ticker
        marketcapattimeofrating
        recentmarketcap
        assetclass
        maxpossiblereturn
        projectedirr
        actualreturnsinceposted
        whydidyouchangeyourmind
        lastratingdate
        stillinvested
        cumulativereturn
        cumulativecapitalgained
        type
        createdAt
        marketcapattimeofexit
        yes
        updatedAt
      }
      nextToken
    }
  }
`;
