import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Amplify, API, Auth, graphqlOperation} from "aws-amplify";
import awsconfig from "../aws-exports";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { useNavigate } from "react-router-dom";
import './styles/createpost.css'
import { withAuthenticator } from '@aws-amplify/ui-react';
import { TextField, Button, Box, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import { all } from 'axios';

const cronstrue = require('cronstrue');


const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: "sk-ndLtBq9EkBPLAOp1rLRUT3BlbkFJZSSXVQKHEiSpkKtcX2V",
});
const openai = new OpenAIApi(configuration);

Amplify.configure(awsconfig);

const today = new Date();
today.setDate(today.getDate() + 1);
const tomorrow = today.toISOString().split('T')[0];

const Createpost = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [marketCap, setMarketCap] = useState('');
    const [targetMarketCap, setTargetMarketCap] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [targetDate, setTargetDate] = useState('');
    const [allocationDollars, setAllocationDollars] = useState(0);
    const [allocationPercent, setAllocationPercent] = useState(0);
    const [name, setName] = useState('');
    const [tokenid, setTokenid] = useState('');
    const [ticker, setTicker] = useState('');
    const [longOrShort, setLongOrShort] = useState('long');
    const [assetClass, setAssetClass] = useState('stock');
    const [keywords, setKeywords] = useState([]);
    const [inwhichscenarioswouldthisfail, setinwhichscenarioswouldthisfail] = useState('');
    const [didYouCheckTicker, setDidYouCheckTicker] = useState(false);
    const [answer, setAnswer] = useState('');

    const [charLimitForTitle, setCharLimitForTitle] = useState(100);
    const [charLimitForContent, setCharLimitForContent] = useState(1000);
    const [charLimitForinwhichscenarioswouldthisfail, setCharLimitForinwhichscenarioswouldthisfail] = useState(500);

    const [tickerLockedIn, setTickerLockedIn] = useState(false);
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userIdIs, setUserIdIs] = useState('');
    const [userRatingIs, setUserRatingIs] = useState(0);
    const [didYouCheckTargetMarketcap, setDidYouCheckTargetMarketcap] = useState(false);

    const [tenpercentofportfolio, setTenpercentofportfolio] = useState(0);
    const [hundredpercentofportfolio, setHundredpercentofportfolio] = useState(0);
    const [value, setValue] = useState(0);

    const hasMountedRef = useRef(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    

    const fetchUserId = async () => {
        try {
          // check if auth == authorId, if yes, then set isAuth to true
          const authUser = await Auth.currentAuthenticatedUser();
          const userIdIsThis = authUser.username;
            setUserIdIs(userIdIsThis);
            const getUser = await API.graphql(graphqlOperation(queries.getUser, { id: userIdIsThis }));
            const userRatingIsThis = getUser.data.getUser.capital;
            setTenpercentofportfolio(userRatingIsThis * 0.1);
            setHundredpercentofportfolio(userRatingIsThis)
            const thisIsIt = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(userRatingIsThis);
            setUserRatingIs(thisIsIt);
        } catch (error) {
            console.log("Error fetching user ID:", error);
        }
    }

    useEffect(() => {
        if (!hasMountedRef.current) {
            fetchUserId();
          hasMountedRef.current = true;
        }
      }, []);


      const convertDateToCronExpression = (dateStr) => {
        const [year, month, day] = dateStr.split('-').map(Number);
        const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
        const dayOfWeek = date.getUTCDay() === 0 ? 7 : date.getUTCDay(); // Sunday = 7, Monday = 1, ..., Saturday = 6
        return `${date.getUTCMinutes()} ${date.getUTCHours()} ${date.getUTCDate()} ${date.getUTCMonth() + 1} ${dayOfWeek} ${date.getUTCFullYear()}`;
      };


      const signout = async () => {
        try {
          await Auth.signOut();
          navigate('/signin');
      } catch (error) {
          console.log('Error signing out: ', error);
      }
  };


    const navigate = useNavigate();

    const handleTitleChange = (event) => {
      setTitle(event.target.value);
        setCharLimitForTitle(100 - event.target.value.length);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
        setCharLimitForContent(1000 - event.target.value.length);
    };

    const handleAllocationDollarsChange = (event) => {
        setAllocationDollars(event.target.value);
        setAllocationPercent((event.target.value / hundredpercentofportfolio));
        setValue(event.target.value);
    };

    const handleKeywordsChange = (event) => {
        const newKeywords = event.target.value.split(/[ ,]+/); // split by comma or space
        setKeywords(newKeywords);
    };
    

    const handleInWhichScenarioWouldThisFail = (event) => {
        setinwhichscenarioswouldthisfail(event.target.value);
        setCharLimitForinwhichscenarioswouldthisfail(500 - event.target.value.length);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleTickerChange = (event) => {
        const input = event.target.value.toUpperCase().replace(/[^A-Z]/g, '');
        setTicker(input);
    };

    const handleMarketCapChange = (event) => {
        setMarketCap(event.target.value);
    };

    const handleTargetMarketCapChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        let num = parseFloat(value);
    
        if (isNaN(num)) {
          setTargetMarketCap(0);
          return;
        }
    
        const suffix = value.trim().slice(-1).toLowerCase();
        switch (suffix) {
          case 'm':
            num *= 1_000_000;
            break;
          case 'b':
            num *= 1_000_000_000;
            break;
          case 't':
            num *= 1_000_000_000_000;
            break;
          default:
            break;
        }
        setTargetMarketCap(num);
      };



    const handleTargetDateChange = (event) => {
        setTargetDate(event.target.value);
    };



    const isFormValid = () => {
        return (
          ticker &&
          assetClass &&
          didYouCheckTicker
        );
      };
      const isFormValidFinal = () => {
        return (
          ticker &&
          longOrShort &&
          assetClass &&
          name &&
          title &&
          keywords.length > 0 &&
          content &&
          inwhichscenarioswouldthisfail &&
          marketCap &&
          targetMarketCap >= 0 &&
          targetDate && 
          allocationDollars > 0 &&
          allocationPercent > 0
        );
      };

      const isFormValidFinalTwo = () => {
        return (
          tokenid &&
          longOrShort &&
          assetClass &&
          name &&
          title &&
          keywords.length > 0 &&
          content &&
          inwhichscenarioswouldthisfail &&
          marketCap &&
          targetMarketCap >= 0 &&
          targetDate && 
          allocationDollars > 0 &&
          allocationPercent > 0
        );
      };


      const isFormValidTwo = () => {
        return (
          name &&
          assetClass &&
          marketCap
        );
      };

    const handleLogin = async () => {
        checkUserFirst();
    }

    const runThisFn = async () => {
        try {
            setTickerLockedIn(true);
            
        } catch (error) {
            console.log('error creating post:', error);
        }   
        }

    const checkUserFirst = async () => {
        try {
            // run fnThatGetsMarketCapFromPolygon and then ask user to confirm the market cap
            await fnThatGetsMarketCapFromPolygon();
            const marketCapConfirmation = window.confirm(`Is the company/token name: ${name} with a $${marketCap.toLocaleString(2)} market cap?`);
            if (marketCapConfirmation === true) {
                console.log("market cap confirmed");
                await runThisFn();
            } else {
                alert("Please try again with a different ticker.");
            }
        } catch (error) {
            console.log('error creating post:', error);
        }
    }

    const fnThatGetsMarketCapFromPolygon = async () => {
        try {
            if (assetClass === 'stock') {
                try {
                    const responseOne = await fetch(`https://api.polygon.io/v2/last/trade/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
                    const dataOne = await responseOne.json();
                    const price = dataOne.results.p;
                    const response = await fetch(`https://api.polygon.io/v3/reference/tickers/${ticker}?apiKey=bQPYqqblazCdhpsMfyywbJpNbZVuTn39`);
                    const data = await response.json();
                    const weightedshares = data.results.weighted_shares_outstanding;
                    const mcap = price * weightedshares;
                    setDidYouCheckTicker(true);
                    setMarketCap(mcap);
                    setName(data.results.name);
                } catch (error) {
                    console.log('error creating post:', error);
                    alert("Could not find ticker for: ." + ticker + "Please try again.");  
                }
            } else if (assetClass === 'crypto') {
                const url = `https://pro-api.coingecko.com/api/v3/search?query=${name}&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRG`;
                  const response = await fetch(url);
                  const data = await response.json();
                  const coins = data.coins;
                  if (coins.length === 0) {
                    console.log(`No results found for "${name}".`);
                    alert(`No results found for "${name}".`);
                    return;
                  }
                  const coin = coins.find((c) => c.name.toLowerCase() === name.toLowerCase());
                  if (coin) {
                    console.log(`Coin ID for "${name}": ${coin.id}`);
                    const response = await fetch(`https://pro-api.coingecko.com/api/v3/coins/${coin.id}/market_chart?vs_currency=usd&days=0&interval=daily&x_cg_pro_api_key=CG-65puo5G37tspbSqsd9jV8qRG`);
                    const data = await response.json();
                    console.log("data is:", data);
                    const marketCapIsThis = data.market_caps[0][1];
                    setDidYouCheckTicker(true);
                    setMarketCap(marketCapIsThis);
                    setTokenid(coin.id);
                  } else {
                    console.log(`No exact match found for "${name}".`);
                    alert(`No exact match found for "${name}".`);
                  }
            }
        } catch (error) {
            console.error(error);
            alert("We ran into this error: ", error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleLogin(); 
    
    }

    // make a function that makes a pop-up that asks the user to confirm the market cap
    // if the user confirms the market cap, then run the function that creates the post
    // if the user does not confirm the market cap, then run the function that asks the user to try again

    const checkTargetMarketCap = async () => {
        try {
            alert(`Is the target market cap $${targetMarketCap.toLocaleString(2)}?`);
            // make a pop-up that asks the user to confirm the target market cap with a yes or no button
            setDidYouCheckTargetMarketcap(true);
            // if the user confirms the target market cap, then run the function that creates the post
            // if the user does not confirm the target market cap, then run the function that asks the user to try again

            //setDidYouCheckTargetMarketcap(true);
        } catch (error) {
            console.log('error creating post:', error, "try again");
        }
    }

    function valuetext(value) {
        return {value};
    }

    function valuetextlabel(value) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }
    

    const handleFinalLoginForStock = async () => {
        try {

            let marketCapConfirmation;
            if (longOrShort === 'long') {
            marketCapConfirmation = window.confirm(`So you want to ${longOrShort} ${name} with ${allocationPercent*100}% of your available capital for a max possible gain of +${((targetMarketCap-marketCap)/marketCap).toFixed(2)*100}% and your target date is: ${targetDate}`);
            } else if (longOrShort === 'short') {
            marketCapConfirmation = window.confirm(`So you want to ${longOrShort} ${name} with ${allocationPercent*100}% of your available capital for a max possible gain of +${((marketCap-targetMarketCap)/marketCap).toFixed(2)*100}% and your target date is: ${targetDate}`);
            }

            if (marketCapConfirmation === true) {
            const userIsThis = await Auth.currentAuthenticatedUser();
            const userIs = userIsThis.username;
            const targetDatey = Date.parse(targetDate);
            const daysToTargetDate = Math.round((targetDatey - Date.now()) / 86400000);
            let annualizedReturn = 0;
            let maxgain = (((targetMarketCap - marketCap)/marketCap));
            if (longOrShort === 'long') {
                maxgain = maxgain * 1;
                annualizedReturn = ((maxgain+1)**(365/daysToTargetDate)-1);
            } else if (longOrShort === 'short') {
                maxgain = maxgain * -1;
                annualizedReturn = ((maxgain+1)**(365/daysToTargetDate)-1);
            } else {
            // Handle invalid longorshort value
                console.log("Invalid longorshort value");
            }
            // check if this user has created a post with this ticker before

            const ifUserHasCreatedAPostWithThisTicker = await API.graphql({query: queries.listPosts, variables: {
                filter: 
                {
                authorId: {eq: userIs}, 
                ticker: {eq: ticker},
                stillinvested: {eq: true}
            }}});
            if (ifUserHasCreatedAPostWithThisTicker.data.listPosts.items.length > 0) {
                // if yes alert the user that they have already created a post with this ticker before
                alert("You have already created a post with this ticker before. Please create a new post with a different ticker, or exit your previous post with this ticker");
                return;
            } else {
                // get User 
                const user = await Auth.currentAuthenticatedUser();
                const usernameIsThis = user.username;
                // get the user's userrating
                const getThisUser = await API.graphql(graphqlOperation(queries.getUser, { id: usernameIsThis }));
                const previousPoints = getThisUser.data.getUser.capital;

                const newPoints = previousPoints - allocationDollars;

                if (newPoints < 0) {
                alert("You cannot rate this post because you don't have enough capital. You currently have " + previousPoints + " capital. Exit an existing position to free up cash.");
                } else {
                const now = new Date();
                const options = { timeZone: 'America/New_York' };
                const todayEST = now.toLocaleString('en-US', options);
                console.log(todayEST);  

                // update the user's  points
                await API.graphql({query: mutations.updateUser, variables: {input: {
                    id: usernameIsThis,
                    capital: newPoints,
                }}});

                     // get the seconds since 1970
                     const postIdIsThis = Date.now().toString();
                     // and create a random number between 0 and 4 digits long to add to the end of the postId to make it unique
                     const randomNumber = Math.floor(Math.random() * 10000);
                     const postId = postIdIsThis + "-rand-" + randomNumber.toString();
     
                     let averagePercentInvestedLong;
                     let averageCapitalInvestedLong;
                     let allUsersTotalCapitalInvestedLong;
                     let allUsersTotalPercentInvestedLong;
     
                     let averagePercentInvestedShort;
                     let averageCapitalInvestedShort;
                     let allUsersTotalCapitalInvestedShort;
                     let allUsersTotalPercentInvestedShort;

                     let numberofinvestorslong;
                     let numberofinvestorsshort;
     
     
                     if (longOrShort === 'short') {
                         averagePercentInvestedLong = 0;
                         averageCapitalInvestedLong = 0;
                         allUsersTotalCapitalInvestedLong = 0;
                         allUsersTotalPercentInvestedLong = 0;
                         averagePercentInvestedShort = allocationPercent;
                         averageCapitalInvestedShort = allocationDollars;
                         allUsersTotalCapitalInvestedShort = allocationDollars;
                         allUsersTotalPercentInvestedShort = allocationPercent;
                         numberofinvestorsshort = 1;
                         numberofinvestorslong = 0;
     
                     } else if (longOrShort === 'long') {
                         averagePercentInvestedShort = 0;
                         averageCapitalInvestedShort = 0;
                         allUsersTotalCapitalInvestedShort = 0;
                         allUsersTotalPercentInvestedShort = 0;
                         averagePercentInvestedLong = allocationPercent;
                         averageCapitalInvestedLong = allocationDollars;
                         allUsersTotalCapitalInvestedLong = allocationDollars;
                         allUsersTotalPercentInvestedLong = allocationPercent;
                        numberofinvestorsshort = 0;
                        numberofinvestorslong = 1;
                     }
     
                     await API.graphql({query: mutations.createPost, variables: {input: {
                         id: postId,
                         longorshort: longOrShort,
                         assetclass: assetClass,
                         ticker: ticker,
                         name: name,
                         title: title,
                         keywords: keywords,
                         content: content,
                         inwhichscenarioswouldthisfail: inwhichscenarioswouldthisfail,
                         targetmarketcap: targetMarketCap,
                         targetdate: targetDate,
                         maxpossiblereturn: maxgain,
                         projectedirr: annualizedReturn,
                         actualreturnsinceposted: 0,
                         stillinvested: true,
                         authorId: userIs,
                         type: "pitch",
                         lastratingdate: todayEST,
                         yesterdaysreturn: 0,
                         cumulativereturn: 0,
                         cumulativecapitalgained: 0,
                         recentmarketcap: marketCap,
                         marketcapattimeofpost: marketCap,
                         numberofinvestors: 1,
                         numberofinvestorsshort: numberofinvestorsshort,
                         numberofinvestorslong: numberofinvestorslong,
                         numberofcomments: 0,
                         averagepercentinvestedlong: averagePercentInvestedLong,
                         averagecapitalinvestedlong: averageCapitalInvestedLong,
                         alluserstotalcapitalinvestedlong: allUsersTotalCapitalInvestedLong,
                         alluserstotalpercentinvestedlong: allUsersTotalPercentInvestedLong,
                         averagepercentinvestedshort: averagePercentInvestedShort,
                         averagecapitalinvestedshort: averageCapitalInvestedShort,
                         alluserstotalcapitalinvestedshort: allUsersTotalCapitalInvestedShort,
                         alluserstotalpercentinvestedshort: allUsersTotalPercentInvestedShort,
                         usercapitalinvested: allocationDollars,
                         userpercentinvested: allocationPercent
                     }}});
                // FOR STOCKS            
                const API_GATEWAY_ENDPOINT = 'https://gvtgd8kred.execute-api.us-east-1.amazonaws.com/alpha';
                const itemId = postId; // Replace with the actual ID of the item to update
                const targetDateTime = new Date(targetDate);
                
                // Create a cron expression based on the targetDate
                const cronExpression = `${targetDateTime.getUTCMinutes()} ${targetDateTime.getUTCHours()} ${targetDateTime.getUTCDate()} ${targetDateTime.getUTCMonth() + 1} ? ${targetDateTime.getUTCFullYear()}`;
                
                console.log("Cron expression:", cronExpression)
                
                const response = await fetch(API_GATEWAY_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: itemId,
                        cronExpression,
                        assetClass: assetClass, // Add the assetClass variable
                        ticker: ticker, // Add the ticker variable for stocks
                        tokenid: 'N/A', // Add the tokenid variable for cryptocurrencies
                        userId: userIs,
                    }),
                });
                
                const data = await response.json();
                console.log("Successfully started new API. Data:", itemId, targetDateTime);
                

                

                alert("Post created successfully.");
                navigate('/');
            }
        }
    } 
    else {
        alert("Please try again.");
    }
        } catch (error) {
            console.log("Error creating post:", error);
        }
    }


    const handleFinalLoginForToken = async () => {
        try {
            let marketCapConfirmation;
            if (longOrShort === 'long') {
            marketCapConfirmation = window.confirm(`So you want to ${longOrShort} ${name} with ${allocationPercent*100}% of your available capital for a max possible gain of +${((targetMarketCap-marketCap)/marketCap).toFixed(2)*100}% and your target date is: ${targetDate}`);
            } else if (longOrShort === 'short') {
            marketCapConfirmation = window.confirm(`So you want to ${longOrShort} ${name} with ${allocationPercent*100}% of your available capital for a max possible gain of +${((marketCap-targetMarketCap)/marketCap).toFixed(2)*100}% and your target date is: ${targetDate}`);
            }            if (marketCapConfirmation === true) {
            const userIsThis = await Auth.currentAuthenticatedUser();
            const userIs = userIsThis.username;
            const targetDatey = Date.parse(targetDate);
            const daysToTargetDate = Math.round((targetDatey - Date.now()) / 86400000);
            let annualizedReturn = 0;
            let maxgain = (((targetMarketCap - marketCap)/marketCap));
            if (longOrShort === 'long') {
                maxgain = maxgain * 1;
                annualizedReturn = ((maxgain+1)**(365/daysToTargetDate)-1);
            } else if (longOrShort === 'short') {
                maxgain = maxgain * -1;
                annualizedReturn = ((maxgain+1)**(365/daysToTargetDate)-1);
            } else {
            // Handle invalid longorshort value
                console.log("Invalid longorshort value");
            }

            // check if this user has created a post with this ticker before

            const ifUserHasCreatedAPostWithThisTicker = await API.graphql({query: queries.listPosts, variables: {
                filter: 
                {
                authorId: {eq: userIs}, 
                tokenid: {eq: tokenid},
                stillinvested: {eq: true}
            }}});
            if (ifUserHasCreatedAPostWithThisTicker.data.listPosts.items.length > 0) {
                // if yes alert the user that they have already created a post with this ticker before
                alert("You have already created a post with this ticker before. Please create a new post with a different ticker, or exit your previous post with this ticker");
                return;
            } else {
                // get User 
                const user = await Auth.currentAuthenticatedUser();
                const usernameIsThis = user.username;
                // get the user's userrating
                const getThisUser = await API.graphql(graphqlOperation(queries.getUser, { id: usernameIsThis }));
                const previousPoints = getThisUser.data.getUser.capital;

                const newPoints = previousPoints - allocationDollars;

                if (newPoints < 0) {
                alert("You cannot rate this post because you don't have enough available capital. You currently have $" + previousPoints + ". Exit an existing position to free up cash.");
                } else {
                const now = new Date();
                const options = { timeZone: 'America/New_York' };
                const todayEST = now.toLocaleString('en-US', options);
                console.log(todayEST);

                
                // update the user's  points
                await API.graphql({query: mutations.updateUser, variables: {input: {
                    id: usernameIsThis,
                    capital: newPoints,
                }}});

                   // get the seconds since 1970
                   const postIdIsThis = Date.now().toString();
                   // and create a random number between 0 and 4 digits long to add to the end of the postId to make it unique
                   const randomNumber = Math.floor(Math.random() * 10000);
                   const postId = postIdIsThis + "-rand-" + randomNumber.toString();
   
                   let averagePercentInvestedLong;
                   let averageCapitalInvestedLong;
                   let allUsersTotalCapitalInvestedLong;
                   let allUsersTotalPercentInvestedLong;
   
                   let averagePercentInvestedShort;
                   let averageCapitalInvestedShort;
                   let allUsersTotalCapitalInvestedShort;
                   let allUsersTotalPercentInvestedShort;
   

                   let numberofinvestorslong;
                   let numberofinvestorsshort;
   
   
                   if (longOrShort === 'short') {
                       averagePercentInvestedLong = 0;
                       averageCapitalInvestedLong = 0;
                       allUsersTotalCapitalInvestedLong = 0;
                       allUsersTotalPercentInvestedLong = 0;
                       averagePercentInvestedShort = allocationPercent;
                       averageCapitalInvestedShort = allocationDollars;
                       allUsersTotalCapitalInvestedShort = allocationDollars;
                       allUsersTotalPercentInvestedShort = allocationPercent;
                       numberofinvestorsshort = 1;
                       numberofinvestorslong = 0;
   
                   } else if (longOrShort === 'long') {
                       averagePercentInvestedShort = 0;
                       averageCapitalInvestedShort = 0;
                       allUsersTotalCapitalInvestedShort = 0;
                       allUsersTotalPercentInvestedShort = 0;
                       averagePercentInvestedLong = allocationPercent;
                       averageCapitalInvestedLong = allocationDollars;
                       allUsersTotalCapitalInvestedLong = allocationDollars;
                       allUsersTotalPercentInvestedLong = allocationPercent;
                      numberofinvestorsshort = 0;
                      numberofinvestorslong = 1;
                   }
   
                   await API.graphql({query: mutations.createPost, variables: {input: {
                       id: postId,
                       longorshort: longOrShort,
                       assetclass: assetClass,
                       tokenid: tokenid,
                       name: name,
                       title: title,
                       keywords: keywords,
                       content: content,
                       inwhichscenarioswouldthisfail: inwhichscenarioswouldthisfail,
                       targetmarketcap: targetMarketCap,
                       targetdate: targetDate,
                       maxpossiblereturn: maxgain,
                       projectedirr: annualizedReturn,
                       actualreturnsinceposted: 0,
                       stillinvested: true,
                       authorId: userIs,
                       type: "pitch",
                       lastratingdate: todayEST,
                       yesterdaysreturn: 0,
                       cumulativereturn: 0,
                       cumulativecapitalgained: 0,
                       recentmarketcap: marketCap,
                       marketcapattimeofpost: marketCap,
                       numberofinvestors: 1,
                       numberofinvestorsshort: numberofinvestorsshort,
                       numberofinvestorslong: numberofinvestorslong,
                       numberofcomments: 0,
                       averagepercentinvestedlong: averagePercentInvestedLong,
                       averagecapitalinvestedlong: averageCapitalInvestedLong,
                       alluserstotalcapitalinvestedlong: allUsersTotalCapitalInvestedLong,
                       alluserstotalpercentinvestedlong: allUsersTotalPercentInvestedLong,
                       averagepercentinvestedshort: averagePercentInvestedShort,
                       averagecapitalinvestedshort: averageCapitalInvestedShort,
                       alluserstotalcapitalinvestedshort: allUsersTotalCapitalInvestedShort,
                       alluserstotalpercentinvestedshort: allUsersTotalPercentInvestedShort,
                       usercapitalinvested: allocationDollars,
                       userpercentinvested: allocationPercent
                   }}});
   
                // FOR STOCKS
                const API_GATEWAY_ENDPOINT = 'https://gvtgd8kred.execute-api.us-east-1.amazonaws.com/alpha';
                const itemId = postId; // Replace with the actual ID of the item to update
                const targetDateTime = new Date(targetDate);
                
                // Create a cron expression based on the targetDate
                const cronExpression = `${targetDateTime.getUTCMinutes()} ${targetDateTime.getUTCHours()} ${targetDateTime.getUTCDate()} ${targetDateTime.getUTCMonth() + 1} ? ${targetDateTime.getUTCFullYear()}`;
                
                console.log("Cron expression:", cronExpression)
                
                const response = await fetch(API_GATEWAY_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: itemId,
                        cronExpression,
                        assetClass: assetClass, // Add the assetClass variable
                        ticker: 'N/A', // Add the ticker variable for stocks
                        tokenid: tokenid, // Add the tokenid variable for cryptocurrencies
                        userId: userIs,
                    }),
                });
                
                const data = await response.json();
                console.log("Successfully started new API. Data:", itemId, targetDateTime);

                alert("Post created successfully.");
                navigate('/');
            }
        }
    }
    else {
        alert("Please try again.");
    }
        } catch (error) {
            console.log("Error creating post:", error);
        }
    }
        
    const handleFinalSubmit = (event) => {
        event.preventDefault();
        if (assetClass === 'stock') {
        handleFinalLoginForStock();
        } else if (assetClass === 'crypto') {
        handleFinalLoginForToken();
        }
    }

    return (
        <div className="Createpost">

<Box>
      <Button
        id="demo-positioned-button"
        variant='outlined'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'white' }}
      >
        {userIdIs}: {userRatingIs}
      </Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { bgcolor: '#0a3d62', color: 'white' },
        }}
      >
        <MenuItem onClick={handleClose}><a className='a' href={`/profile?authorId=${encodeURIComponent(userIdIs)}`}>Profile</a></MenuItem>
        <MenuItem onClick={handleClose}><Link className='a' to="/">
        Timeline </Link></MenuItem>
        <MenuItem onClick={handleClose}><a className='a' href={`/Leaderboard`}>Leaderboard</a></MenuItem>
        <MenuItem onClick={handleClose}><Link className='a' to="/selectticker">
        Create a post </Link></MenuItem>
        <MenuItem onClick={signout}>Logout</MenuItem>
      </Menu>
      </Box>
            
            <h1>Create your pitch</h1>

            {!tickerLockedIn && assetClass === 'stock' && 
            <Button
             variant="outlined"
             onClick={fnThatGetsMarketCapFromPolygon}>
                check ticker</Button>
            }
           {!tickerLockedIn && assetClass === 'stock' && 
            <form onSubmit={handleSubmit}>
                <label>
                    Asset class:
                    <select value={assetClass} onChange={(e) => setAssetClass(e.target.value)} className="selectvalueasset">
                        <option value="stock">stock</option>
                        <option value="crypto">crypto</option>
                    </select>
                </label>
                <br />
                <br />
                <label>
                    Ticker:
                    <textarea type="text" value={ticker} onChange={handleTickerChange} className="tickerchange" />
                </label>
                <br />
                <br />
                <p className='nameofcoisthis'> Name of company: {name} </p>
                <p className='mcapis'> Current market cap: ${marketCap.toLocaleString(2)} USD</p>
                <Button
                variant="outlined"
                 type="submit" className='submitbut' disabled={!isFormValid()}>next step</Button>
            </form>
           }

            {tickerLockedIn && assetClass === 'stock' &&
            <form onSubmit={handleFinalSubmit}>
                <p className='TickerIsThis'>Ticker: {ticker}</p>
                <p className='nameofcoisthis'> Name of token: {name} </p>
                <p className='mcapis'> Current market cap: ${marketCap.toLocaleString(2)} USD</p>
                <br />
                <label>
                    Long or short:
                    <select value={longOrShort} onChange={(e) => setLongOrShort(e.target.value)} className="selectlongorshort">
                        <option value="long">Long</option>
                        <option value="short">Short</option>
                    </select>
                </label>
                <br />
                <br />
                <br />
                <label>
                    Title of your post:
                    <textarea type="text" value={title} onChange={handleTitleChange} className="themftitle" maxLength={100}  />
                    <br />
                    Characters left: {charLimitForTitle}
                </label>
                <br />
                <br />
                <label>
                    Keywords (example: AI, Consumer, Healthcare, GARP, special situations ) **Press enter to add a space if needed:
                    <textarea value={(keywords.join(', ')).toLocaleLowerCase()} onChange={handleKeywordsChange} maxLength={200} />
                </label>
                <p> Current keywords: {keywords.join(', ')}</p>
                <br />
                <label>
                    What do you see that the market doesn't?
                    <textarea type="text" value={content} onChange={handleContentChange} className="thetextboxfforthesis" maxLength={1000}/>
                    <p>Characters left: {charLimitForContent}</p>
                </label>
                <label>
                    In which scenarios would the thesis fail?
                    <textarea type="text" value={inwhichscenarioswouldthisfail} onChange={handleInWhichScenarioWouldThisFail} className="fail" maxLength={500}/>
                    <p>Characters left: {charLimitForinwhichscenarioswouldthisfail}</p>
                </label>
                <br />
                <br />
                <label>
                    Target market cap (e.g. 34B or 1.2T, or 385M):
                    <input
                        type="text"
                        className="targetmcapnum"
                        value={inputValue}
                        onChange={handleTargetMarketCapChange}
                    />

{longOrShort === "long" && ((targetMarketCap - marketCap) / marketCap).toFixed(2)*100 > 0 && (<p>${targetMarketCap.toLocaleString(2)} (+{((targetMarketCap - marketCap)/ marketCap).toFixed(2)*100}%)</p>)}
                    {longOrShort === "long" && ((targetMarketCap - marketCap) / marketCap).toFixed(2)*100 < 0 && (<p>${targetMarketCap.toLocaleString(2)} (-{((marketCap - targetMarketCap) / marketCap).toFixed(2)*100}%). You are going long and your target price is below entry. Please fix.</p>)}
                    {longOrShort === "short" && ((marketCap - targetMarketCap) / marketCap).toFixed(2)*100 > 0 && (<p>${targetMarketCap.toLocaleString(2)} (+{((marketCap - targetMarketCap) / marketCap).toFixed(2)*100}%)</p>)}
                    {longOrShort === "short" && ((marketCap - targetMarketCap) / marketCap).toFixed(2)*100 < 0 && (<p>${targetMarketCap.toLocaleString(2)} ({((marketCap - targetMarketCap) / marketCap).toFixed(2)*100}%). You are shorting and your target price is higher than entry. Please fix.</p>)}

                </label>
                <br />
                <br />
                <label>
                    Target date:
                    <input type="date" className='targetdatevalue' min={tomorrow} value={targetDate} onChange={handleTargetDateChange} />
                </label>
                <br />
                <br />
                <Slider
                    aria-label="Small steps"
                    value={value} // This will make the Slider's value controlled by the state.
                    getAriaValueText={valuetext}
                    valueLabelFormat={valuetextlabel}
                    step={tenpercentofportfolio}
                    marks
                    min={tenpercentofportfolio}
                    max={hundredpercentofportfolio}
                    valueLabelDisplay="auto"
                    onChange={handleAllocationDollarsChange}
                />

                <p>I am investing {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(allocationDollars)} ({allocationPercent*100}% of my availabe cash) in my idea. </p>


                <Button
                variant="outlined"
                type="submit" className='submitbut' disabled={!isFormValidFinal()}>post
                </Button>
            </form>
            }


            {!tickerLockedIn && assetClass === 'crypto' && 

            <Button
            variant="outlined" onClick={fnThatGetsMarketCapFromPolygon}>check token</Button>
            }
            {!tickerLockedIn && assetClass === 'crypto' && 

            <form onSubmit={handleSubmit}>
                <label>
                    Asset class:
                    <select value={assetClass} onChange={(e) => setAssetClass(e.target.value)} className="selectvalueasset">
                        <option value="stock">stock</option>
                        <option value="crypto">crypto</option>
                    </select>
                </label>
                <br />
                <br />
                <label>
                    Name of token:
                    <textarea type="text" value={name} onChange={handleNameChange} className="namechange" />
                </label>
                <br />
                <br />
                <p className='nameofcoisthis'> Name of token: {name} </p>
                <p className='mcapis'> Current market cap: ${marketCap.toLocaleString(2)} USD</p>
                <Button
                variant="outlined" type="submit" className='submitbut' disabled={!isFormValidTwo()}>next step</Button>
            </form>


            }

            {tickerLockedIn && assetClass === 'crypto' &&
            <form onSubmit={handleFinalSubmit}>
                <p className='TickerIsThis'>Ticker: {ticker}</p>
                <p className='nameofcoisthis'> Name of token: {name} </p>
                <p className='mcapis'> Current market cap: ${marketCap.toLocaleString(2)} USD</p>
                <br />
                <label>
                    Long or short:
                    <select value={longOrShort} onChange={(e) => setLongOrShort(e.target.value)} className="selectlongorshort">
                        <option value="long">Long</option>
                        <option value="short">Short</option>
                    </select>
                </label>
                <br />
                <br />
                <br />
                <label>
                    Title of your post:
                    <textarea
                    type="text" value={title} onChange={handleTitleChange} className="themftitle" maxLength={100}  />
                    <br />
                    Characters left: {charLimitForTitle}
                </label>
                <br />
                <br />
                <label>
                    Keywords (example: AI, Consumer, Healthcare, GARP, special situations ) **Press enter to add a space if needed: 
                    <textarea value={(keywords.join(', ')).toLocaleLowerCase()} onChange={handleKeywordsChange} maxLength={200} />
                </label>
                <p>Current keywords: {keywords.join(', ')}</p>
                <br />
                <label>
                    What do you see that the market doesn't?
                    <textarea type="text" value={content} onChange={handleContentChange} className="thetextboxfforthesis" maxLength={1000}/>
                    <p>Characters left: {charLimitForContent}</p>
                </label>
                <label>
                    In which scenarios would the thesis fail?
                    <textarea type="text" value={inwhichscenarioswouldthisfail} onChange={handleInWhichScenarioWouldThisFail} className="fail" maxLength={500}/>
                    <p>Characters left: {charLimitForinwhichscenarioswouldthisfail}</p>
                </label>
                <br />
                <br />
                <label>
                    Target market cap (e.g. 34B or 1.2T, or 385M):
                    <input
                        type="text"
                        className="targetmcapnum"
                        value={inputValue}
                        onChange={handleTargetMarketCapChange}
                    />
                    {longOrShort === "long" && ((targetMarketCap - marketCap) / marketCap).toFixed(2)*100 > 0 && (<p>${targetMarketCap.toLocaleString(2)} (+{((targetMarketCap - marketCap)/ marketCap).toFixed(2)*100}%)</p>)}
                    {longOrShort === "long" && ((targetMarketCap - marketCap) / marketCap).toFixed(2)*100 < 0 && (<p>${targetMarketCap.toLocaleString(2)} (-{((marketCap - targetMarketCap) / marketCap).toFixed(2)*100}%). You are going long and your target price is below entry. Please fix.</p>)}
                    {longOrShort === "short" && ((marketCap - targetMarketCap) / marketCap).toFixed(2)*100 > 0 && (<p>${targetMarketCap.toLocaleString(2)} (+{((marketCap - targetMarketCap) / marketCap).toFixed(2)*100}%)</p>)}
                    {longOrShort === "short" && ((marketCap - targetMarketCap) / marketCap).toFixed(2)*100 < 0 && (<p>${targetMarketCap.toLocaleString(2)} ({((marketCap - targetMarketCap) / marketCap).toFixed(2)*100}%). You are shorting and your target price is higher than entry. Please fix.</p>)}

                </label>
                <br />
                <br />
                <label>
                    Target date:
                    <input type="date" className='targetdatevalue' min={tomorrow} value={targetDate} onChange={handleTargetDateChange} />
                </label>
                <br />
                <br />
                <Slider
                    aria-label="Small steps"
                    value={value} // This will make the Slider's value controlled by the state.
                    getAriaValueText={valuetext}
                    valueLabelFormat={valuetextlabel}
                    step={tenpercentofportfolio}
                    marks
                    min={tenpercentofportfolio}
                    max={hundredpercentofportfolio}
                    valueLabelDisplay="auto"
                    onChange={handleAllocationDollarsChange}
                />

                <p>I am investing {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(allocationDollars)} ({allocationPercent*100}% of my availabe cash) in my idea. </p>

                <Button
                variant="outlined" 
                type="submit" className='submitbut' disabled={!isFormValidFinalTwo()}>post
                </Button>
            </form>
            }
        </div>
    )
}

export default withAuthenticator(Createpost);
